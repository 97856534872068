import classNames from 'classnames';
import styles from './styles.module.scss';
import { ICheckBoxesProps } from './types';
import React from 'react';

export const CheckBoxesField = (props: ICheckBoxesProps) => {
  const {
    label,
    onChange,
    customLabel,
    containerClassName,
    checkboxContainer = '',
    errorMessage,
    labelName = '',
    isChecked,
    options,
    ...rest
  } = props;

  const customCheckboxClasses = classNames(
    styles.customCheckbox,
    styles.smallCustomCheckbox,
    styles.smallContainerSize,
  );

  const handleOnClick = e => {
    onChange?.(e);
  };

  return (
    <div>
      <div className={styles.checkboxParentContainer}>
        {options.map((option, index) => (
          <div
            key={option?.value}
            className={classNames(styles.container, containerClassName)}
            role="button"
            onClick={() => handleOnClick(option)}
          >
            <div
              className={classNames(styles.inputContainer, checkboxContainer)}
            >
              <input
                className={styles.input}
                type="checkbox"
                checked={isChecked(option)}
                readOnly
                {...rest}
              />
              <span className={customCheckboxClasses} />
            </div>
            {customLabel && customLabel(option)}
          </div>
        ))}
      </div>
      {!!errorMessage && (
        <div>
          <span className={styles.errorMessage}>{errorMessage}</span>
        </div>
      )}
    </div>
  );
};
