import React, { useState } from 'react';
import classnames from 'classnames';
// styles
import styles from './styles.module.scss';

const FieldLabel = props => {
  const { value, className, isMandatory = false } = props;

  return (
    <>
      <div className={styles.labelWrapper}>
        {value.length > 40 && className !== 'ticketMappingLabel' && (
          <div className={styles.tooltip}>{value}</div>
        )}
        <span className={isMandatory ? styles.isMandatory : ""}>
          <label
            className={classnames(styles.fieldLabel, {
              [styles[className]]: !!className,
              [styles["overflowText"]]: value.length > 40,
            })}
            >
            {value}
          </label>
        </span>
      </div>
    </>
  );
};

export default FieldLabel;
