import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
// api + models
import eventAPI from '@/api/event';
import videoAPI from '@/api/video';
// components
import Button from '@/components/ui/buttons/Button';
import Video from '@/components/ui/media/Video';
import Label from '@/components/ui/content/Label';
import Loader from '@/components/ui/Loader';
import NewLabel, {
  INewLabelColors,
  INewLabelSizes,
  INewLabelMargins,
} from '@/components/ui/content/NewLabel';
import UploadFileIcon from '@/components/ui/icons/UploadFile';
// styles + types + utils + configs
import styles from './styles.module.scss';
import { IButtonTypes } from '@/components/ui/buttons/Button/types';
import { IVideoUploadFieldProps } from './types';
import { fileWithURL } from '@/utils/helpers';
import { VIDEO_URL } from '@/config';
import FieldLabel from '../FieldLabel';
import FormValidationErrorTooltip from '../../FormValidationErrorTooltip';
import * as UpChunk from '@mux/upchunk';
import { IVideoStatusType } from '@/types/video';
import { getVideoMuxUrl } from '@/utils/helpers';
import TooltipDiv from '@/components/ui/icons/TooltipDiv';

const VideoUploadField = (props: IVideoUploadFieldProps) => {
  let { value, resizeProps } = props;
  let { fileType, maxFileSize, minWidth, maxWidth, minHeight, maxHeight } = props;
  const { error, label, onChange, submitFailed, readOnly, isLastElement, labelStyles, isMandatory, tooltip } = props;
  const [uploadedVideoFileName, setUploadedVideoFileName] = useState(value || '');
  const [videoUploading, setVideoUploading] = useState(false);
  const [videoReadyToDisplay, setVideoReadyToDisplay] = useState(false);

  const [videoId, setVideoId] = useState('');
  const [videoStatus, setVideoStatus] = useState('');
  const [upChunkStatus, setUpChunkStatus] = useState('');
  const [loaderLabelText, setLoaderLabelText] = useState('Upload Video');
  const { eventId } = useParams();
  const dispatch = useDispatch();

  const validationError = submitFailed && error;

  if (!fileType) {
    fileType = "mp4";
  }
  if (!maxFileSize) {
    maxFileSize = 500; //500 MB default file size
  }

  if (value === null) {
    value = '';
  }

  useEffect(() => {
    setUploadedVideoFileName(value);
  }, [value]);

  const handleVideoLoad = () => {
    setVideoReadyToDisplay(true);
    setVideoUploading(false);
  };

  const resetState = () => {
    setVideoReadyToDisplay(false);
    setVideoUploading(false);
    setUploadedVideoFileName('');
    onChange('');
  }

  const getBackendVideoUploadPermission = async (fileName: string) => {
    const { data } = await eventAPI.getImageUploadUrl(fileName);
    return data;
  };

  const uploadFileToS3 = async (s3Url: string, file: any) => {
    const s3UploadResponse = await eventAPI.uploadS3Image(s3Url, file);
    return s3UploadResponse;
  }

  useEffect(() => {
    const getVideoStatus = async () => {
      if (!videoId) {
        return false;
      }
      const { data } = await videoAPI.getVideoById(videoId);
      setVideoStatus(data.status);
      if (data.status === IVideoStatusType.READY) {
        setUploadedVideoFileName(getVideoMuxUrl(data.muxPlaybackId));
        onChange(getVideoMuxUrl(data.muxPlaybackId));
        setLoaderLabelText("Upload Video");
        setVideoUploading(false);
        return true;
      }
      if (data.status === IVideoStatusType.ERRORED || data.status === IVideoStatusType.CANCELLED) {
        setVideoUploading(false);
        setLoaderLabelText("Upload Video");
        dispatch({
          type: 'global/addDangerToast',
          payload: { description: 'Intro Video Upload Failed' }
        });
        return true;
      }
      return false;
    }

    const asyncInterval = async (callback, ms) => {
      return new Promise((resolve, reject) => {
        const interval = setInterval(async () => {
          if (await callback()) {
            resolve();
            clearInterval(interval);
          }
        }, ms);
      });
    }

    const checkVideoStatus = async () => {
      try {
        await asyncInterval(getVideoStatus, 5 * 1000);
      } catch (e) {
        console.log('error handling');
      }
      console.log("Done!");
    }

    if (upChunkStatus === 'success' && videoId !== '' && videoStatus === IVideoStatusType.UPLOADING) {
      checkVideoStatus();
    }
  }, [videoId, upChunkStatus, videoStatus]);

  const getMuxUrl = async () => {
    var response = await videoAPI.getMuxUrl(eventId).catch(function (error) {
      return null;
    });
    if (!response) {
      return null;
    }
    return response.data;
  }

  const handleFileChoice = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    var fileUpload = e.target;
    if (typeof (fileUpload.files) === "undefined") {
      dispatch({ type: 'global/addDangerToast', payload: { description: 'This browser does not support HTML5.' } });
      return;
    }
    let fileTypes = fileType.split(',');
    const chosenFile = fileUpload.files[0];
    if (!chosenFile) {
      dispatch({ type: 'global/addDangerToast', payload: { description: 'Please select a valid video file.' } });
      return;
    }
    const { name: chosenFileName } = chosenFile;
    const lastDotIndex = chosenFileName.lastIndexOf('.');
    const fileExtension = chosenFileName.substring(lastDotIndex + 1);

    if (!fileTypes.includes(fileExtension.toLocaleLowerCase().trim())) {
      dispatch({ type: 'global/addDangerToast', payload: { description: 'Please select a valid video file.' } });
      return;
    }

    if (chosenFile.size / Math.pow(1024, 2) > maxFileSize) {
      let fileSizeFormat = `${maxFileSize} MB`;
      if (maxFileSize >= 1000) {
        fileSizeFormat = `${maxFileSize / 1000} GB`;
      }
      dispatch({
        type: 'global/addDangerToast',
        payload: {
          description: `Please select video file below ${fileSizeFormat}`,
        },
      });
      return;
    }

    var video = document.createElement('video');
    video.onloadedmetadata = async () => {
      let videoWidth = video.videoWidth;
      let videoHeight = video.videoHeight;

      if (minWidth && videoWidth < minWidth) {
        dispatch({ type: 'global/addDangerToast', payload: { description: `Video frame minimum width should be ${minWidth} pixels` } });
        return;
      }
      if (minHeight && videoHeight < minHeight) {
        dispatch({ type: 'global/addDangerToast', payload: { description: `Video frame minimum height should be ${minHeight} pixels` } });
        return;
      }
      if (maxWidth && videoWidth > maxWidth) {
        dispatch({ type: 'global/addDangerToast', payload: { description: `Video frame maximum width should be ${maxWidth} pixels` } });
        return;
      }
      if (maxHeight && videoHeight > maxHeight) {
        dispatch({ type: 'global/addDangerToast', payload: { description: `Video frame maximum height should be ${maxHeight} pixels` } });
        return;
      }

      setVideoUploading(true);
      setLoaderLabelText("0%");

      const uploadResponse = await getMuxUrl();
      if (!uploadResponse) {
        setVideoUploading(false);
        setLoaderLabelText("");
        dispatch({
          type: 'global/addDangerToast',
          payload: { description: 'Video Upload Failed' }
        });
        return;
      }

      setVideoId(uploadResponse.video_id);
      setVideoStatus(IVideoStatusType.UPLOADING);
      setUpChunkStatus('uploading');

      const upload = UpChunk.createUpload({
        endpoint: uploadResponse.mux_upload_url,
        file: chosenFile,
        chunkSize: 5120, // Uploads the file in ~5mb chunks
      });

      // subscribe to events
      upload.on('error', err => {
        console.error('💥 🙀', err.detail);
        resetState();
        dispatch({
          type: 'global/addDangerToast',
          payload: { description: 'Video Upload Failed' }
        });
      });

      upload.on('progress', progress => {
        console.log(`So far we've uploaded ${progress.detail}% of this file.`);
        setLoaderLabelText(parseInt(progress.detail) < 100 ? `${parseInt(progress.detail)}%` : '100%');
        setUpChunkStatus(parseInt(progress.detail) < 100 ? 'progress' : 'success');
      });

      upload.on('success', () => {
        console.log("Wrap it up, we're done here. 👋");
        setLoaderLabelText("100%");
        setUpChunkStatus('success');
      });
    };
    video.onerror = () => {
      dispatch({ type: 'global/addDangerToast', payload: { description: 'Please select a valid video file.' } });
      resetState();
      return;
    };
    video.src = URL.createObjectURL(chosenFile);
  }

  const uploadedVideoFilenameExists = uploadedVideoFileName && uploadedVideoFileName.length > 0;
  const showLoader = (uploadedVideoFilenameExists && !videoReadyToDisplay) || videoUploading;

  const getVideoUploadButtonClass = () => {
    const baseClass = 'uploadContainer';
    if ((!videoUploading && !uploadedVideoFilenameExists) || showLoader) {
      return baseClass;
    } else {
      return `${baseClass}Hidden`;
    }
  }

  const getVideoDisplayButtonClass = () => {
    const baseClass = 'videoUploadField';
    if (!videoReadyToDisplay) {
      return `${baseClass}Hidden`;
    } else {
      return baseClass;
    }
  }

  const videoUploadButton = (
    <div className={styles[`${getVideoUploadButtonClass()}`]} style={resizeProps && { height: resizeProps.height / 10 + 'vh' }}>
      {showLoader ? <div>
        <Loader styleClass="videoUploadField" />
        {!resizeProps && <NewLabel
          text='Processing Video...'
          labelSize={INewLabelSizes.SMALL}
          labelColor={INewLabelColors.GREY}
          labelMargin={INewLabelMargins.SMALL_MARGIN_BOTTOM} />}
      </div> : <div>
        {!resizeProps && <NewLabel
          text='Upload Experience Video'
          labelSize={INewLabelSizes.SMALL}
          labelColor={INewLabelColors.NEUTRAL_DARKEST_3}
          labelMargin={INewLabelMargins.SMALL_MARGIN_BOTTOM} />}
        <Button
          type="button"
          styleClass={IButtonTypes.NO_BG_BLACK_SQUARE}
          label='Upload Video'
        />
      </div>
      }
      <input
        className={`${styles.fileChoiceInputField}`}
        onChange={e => handleFileChoice(e)}
        type="file"
        accept="video/*"
        disabled={readOnly}
      />
    </div>
  );

  const marginRightClass = `${styles.addMarginRight}`;
  const errorContainer = `${styles.errorContainer}`;
  const containerStyles = classnames(
    errorContainer,
    { [marginRightClass]: !isLastElement }
  );

  return (
    // <div className={containerStyles}>
    //   <div
    //     className={classnames(`${styles.videoUploadContainer}`, {
    //       [`${styles.validationError}`]: validationError,
    //     })}
    //     style={resizeProps && { background: 'var(--neutral-base-1)'  }}
    //   >
    //     {label ? <Label value={label} styleClass="videoUploadField" /> : null}
    //     {videoUploadButton}
    //     <Video
    //       onLoad={handleVideoLoad}
    //       styleClass={getVideoDisplayButtonClass()}
    //       loop={true}
    //       url={fileWithURL(uploadedVideoFileName)}
    //       autoplay={true}
    //       playsinline={true}
    //       controls={false}
    //       showLoader={true}
    //       muted={true}
    //       deletable={true}
    //       onDelete={resetState}
    //       resizeProps={resizeProps}
    //     />
    //   </div>
    //   {validationError && (
    //     <Label value={error} styleClass="fieldValidationError" />
    //   )}
    // </div>
    <div className={styles.container}>
      <div className={styles.titleLabelContainer}>
        {
          label && label.length ? (
            <FieldLabel value={label} className={labelStyles} />
          ) : null
        }
        {
          isMandatory &&
          <FieldLabel value={`*`} className={labelStyles} />
        }
        {
          label && label.length && tooltip ? (
            <TooltipDiv dataFor={`tooltip_video_upload_${label}`} tooltipText={tooltip} />
          ) : null
        }
      </div>
      <div className={styles.imageUploadField}>
        <VideoIcon />
        <div className={styles.imageUploadText}>
          {(!videoUploading && value) ? `Replace Video` : loaderLabelText}
        </div>
        <input
          className={`${styles.fileChoiceInputField}`}
          onChange={(e) => handleFileChoice(e)}
          type='file'
          disabled={readOnly}
          accept="video/*"
          title={" "}
        />
      </div>
      { error && <FormValidationErrorTooltip value={error} />}
    </div>
  );
}

const VideoIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 3C12.414 3 12.75 3.336 12.75 3.75V6.9L16.6597 4.1625C16.8293 4.044 17.0632 4.08525 17.1825 4.2555C17.226 4.3185 17.25 4.3935 17.25 4.47V13.53C17.25 13.737 17.082 13.905 16.875 13.905C16.7978 13.905 16.7227 13.881 16.6597 13.8375L12.75 11.1V14.25C12.75 14.664 12.414 15 12 15H1.5C1.086 15 0.75 14.664 0.75 14.25V3.75C0.75 3.336 1.086 3 1.5 3H12ZM11.25 4.5H2.25V13.5H11.25V4.5ZM7.5 6V9H9.75L6.75 12L3.75 9H6V6H7.5ZM15.75 6.63075L12.75 8.73075V9.26925L15.75 11.3693V6.63V6.63075Z" fill="#222222" />
    </svg>
  );
}

export default VideoUploadField;
