import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
// api + models
import eventAPI from '@/api/event';
// styles + types + utils + configs
import styles from './styles.module.scss';
import { ImageType } from './types';
import FieldLabel from '../FieldLabel';
import FormValidationErrorTooltip from '../../FormValidationErrorTooltip';
import TooltipDiv from '@/components/ui/icons/TooltipDiv';

const FileUploadField = (props) => {
  const { error, label, onChange, submitFailed, readOnly, value, labelStyles, isMandatory, tooltip } = props;
  // const { label = "Select a file from your computer", onChange, value } = props;

  const onFileChange = (e) => {
    e.preventDefault();
    onChange(e.target.files[0]);
  };

  const deleteFile = () => {
    onChange(null);
  }

  // if (value === null) {
  //   value = '';
  // } else if(value){
  //   const lastDotIndex = value.lastIndexOf('.');
  //   const fileExtension = value.substring(lastDotIndex + 1);
  //   if(!['jpg', 'jpeg', 'png'].includes(fileExtension.toLocaleLowerCase().trim())){
  //     value = '';
  //   }
  // }

  // useEffect(() => {
  //   setUploadedImageFileName(value);
  // }, [value]);

  const getBackendImageUploadPermission = async (fileName: string) => {
    const { data } = await eventAPI.getImageUploadUrl(fileName);
    return data;
  };

  const uploadFileToS3 = async (s3Url: string, file: any) => {
    const s3UploadResponse = await eventAPI.uploadS3Image(s3Url, file);
    return s3UploadResponse;
  }

  const [uploadedImageFileName, setUploadedImageFileName] = useState(value || '');
  const handleFileChoice = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const fileUUID = uuidv4();
    const targetFiles = e.target.files;
    if (targetFiles) {
      // extract file and get its properties
      const chosenFile = targetFiles[0];
      const { name: chosenFileName } = chosenFile;
      const lastDotIndex = chosenFileName.lastIndexOf('.');
      const fileExtension = chosenFileName.substring(lastDotIndex + 1);

      // update the file with the new uuid name
      const fileName = `${fileUUID}.${fileExtension}`;
      const blob = chosenFile.slice(0, chosenFile.size, chosenFile.type);
      const renamedFile = new File([blob], fileName, { type: chosenFile.type });

      // get the pre-signed request from the backend
      const { presignedPutUrl: s3UploadUrl } = await getBackendImageUploadPermission(fileName);

      // upload the file to s3
      const uploadedS3File = await uploadFileToS3(s3UploadUrl, renamedFile);
      if (uploadedS3File) {
        setUploadedImageFileName(fileName);
        onChange(fileName);
      } else {
        console.log("Oops there's an error when uploading file to s3. :(")
        // resetState();
      }
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.titleLabelContainer}>
        {
          label && label.length ? (
            <FieldLabel value={label} className={labelStyles} />
          ) : null
        }
        {
          isMandatory &&
          <FieldLabel value={`*`} className={labelStyles} />
        }
        {
          label && label.length && tooltip ? (
            <TooltipDiv dataFor={`tooltip_file_upload_${label}`}  tooltipText={tooltip}/>
          ) : null
        }
      </div>
      <div className={styles.imageUploadField}>
        <FileIcon />
        <div className={styles.imageUploadText}>
          {value ? `Replace file` : `Upload file`}
        </div>
        <input
          className={`${styles.fileChoiceInputField}`}
          onChange={(e) => handleFileChoice(e)}
          type='file'
          disabled={readOnly}
          title={" "}
        />
      </div>
      { error && <FormValidationErrorTooltip value={error} />}
    </div>
  );
}

const FileIcon = () => {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.75 9.60352H12L9 12.6035L6 9.60352H8.25V6.60352H9.75V9.60352ZM11.25 3.60352H3.75V15.6035H14.25V6.60352H11.25V3.60352ZM2.25 2.84752C2.25 2.43652 2.58525 2.10352 2.99925 2.10352H12L15.75 5.85352V16.3483C15.7507 16.4468 15.732 16.5444 15.6949 16.6357C15.6579 16.7269 15.6032 16.81 15.534 16.8801C15.4649 16.9503 15.3826 17.0061 15.2919 17.0444C15.2011 17.0827 15.1037 17.1028 15.0052 17.1035H2.99475C2.79778 17.1021 2.60926 17.0233 2.46991 16.8841C2.33056 16.7449 2.25157 16.5565 2.25 16.3595V2.84752Z" fill="#222222" />
    </svg>
  );
}

export default FileUploadField;