import { IEmptyStateParentSources, ISegmentStatus } from './types';
import { IContentTextStyleTypes } from '@/types';
import moment from 'moment';

export const getTextByParentSource = (
  event,
  parentSource: IEmptyStateParentSources,
  config,
  isBroadcasting,
  filteredSegments,
  allSegments,
  pageSections,
  currentDay,
) => {
  const { startDateTime, title, endDateTime } = event;
  switch (parentSource) {
    case IEmptyStateParentSources.DISCUSSION_LIST: {
      const { firstName, numOpenTables } = config;
      return {
        heading: [
          {
            text: `${firstName}! `,
            style: IContentTextStyleTypes.UNSET,
          },
          {
            text: 'You are right on time!',
            style: IContentTextStyleTypes.BOLDED,
          },
        ],
        subtext: [
          {
            text: 'We have around ',
            style: IContentTextStyleTypes.UNSET,
          },
          {
            text: `${numOpenTables} open tables `,
            style: IContentTextStyleTypes.BOLDED,
          },
          {
            text: 'for you to ',
            style: IContentTextStyleTypes.UNSET,
          },
          {
            text: 'network with professionals',
            style: IContentTextStyleTypes.BOLDED,
          },
        ],
      };
    }
    case IEmptyStateParentSources.DISCUSSION_LIST_EMPTY: {
      const { firstName } = config;
      return {
        heading: [
          {
            text: `We're glad to have you here, ${firstName}`,
            style: IContentTextStyleTypes.UNSET,
          },
        ],
        subtext: [
          {
            text: 'Rooms are not open yet.',
            style: IContentTextStyleTypes.UNSET,
          },
          {
            text: 'Please explore other zones of the event',
            style: IContentTextStyleTypes.UNSET,
          },
        ],
      };
    }
    case IEmptyStateParentSources.SPEAKER_LIST_EMPTY: {
      const { firstName } = config;
      return {
        heading: [
          {
            text: `${firstName}! `,
            style: IContentTextStyleTypes.UNSET,
          },
          {
            text: 'You are right on time!',
            style: IContentTextStyleTypes.BOLDED,
          },
        ],
        subtext: [
          {
            text:
              'Our organizers are still coordiating with speakers. Until then, check out the Lobby!',
            style: IContentTextStyleTypes.UNSET,
          },
        ],
      };
    }
    case IEmptyStateParentSources.EXPO_LIST_EMPTY: {
      const { firstName } = config;
      return {
        heading: [
          {
            text: `We're glad to have you here, ${firstName}`,
            style: IContentTextStyleTypes.UNSET,
          },
        ],
        subtext: [
          {
            text: 'Expos are not open yet.',
            style: IContentTextStyleTypes.UNSET,
          },
          {
            text: 'Please explore other zones of the event',
            style: IContentTextStyleTypes.UNSET,
          },
        ],
      };
    }
    case IEmptyStateParentSources.SPONSOR_LIST_EMPTY: {
      const { firstName } = config;
      return {
        heading: [
          {
            text: `${firstName}! `,
            style: IContentTextStyleTypes.UNSET,
          },
          {
            text: 'You are right on time!',
            style: IContentTextStyleTypes.BOLDED,
          },
        ],
        subtext: [
          {
            text:
              'Our organizers are still contacting sponsors. Until then, check out the Lobby!',
            style: IContentTextStyleTypes.UNSET,
          },
        ],
      };
    }
    case IEmptyStateParentSources.SCHEDULE_LIST_EMPTY: {
      const { firstName } = config;
      return {
        heading: [
          // {
          //   text: `${firstName}! `,
          //   style: IContentTextStyleTypes.UNSET,
          // },
          {
            text: `We're glad to have you here, ${firstName}`,
            style: IContentTextStyleTypes.UNSET,
          },
        ],
        subtext: [
          {
            text: 'Schedule is not out yet.',
            style: IContentTextStyleTypes.UNSET,
          },
          {
            text: 'Please explore other zones of the event',
            style: IContentTextStyleTypes.UNSET,
          },
        ],
      };
    }
    case IEmptyStateParentSources.STAGE_ATTENDEE: {
      const { firstName } = config;
      // Current day segments
      const currentDaySegments = filteredSegments.filter(
        item =>
          moment(item.startDateTime).format('DD/MM/YYYY') ===
          moment(currentDay).format('DD/MM/YYYY'),
      );
      // check for is event is started
      const isBeforeDayOfEvent = currentDay.getTime() <= new Date(startDateTime).getTime();
      const isEventCompleted = currentDay.getTime() >= new Date(endDateTime).getTime();

      const completedSegmentsFromAllSeg = allSegments.filter(
        item => item.broadcastStatus === ISegmentStatus.COMPLETED,
      );
      const pendingSegmentsFromAllSeg = allSegments.filter(
        item => item.broadcastStatus === ISegmentStatus.PENDING,
      );
      const startedSegmentsFromAllSeg = allSegments.filter(
        item => item.broadcastStatus === ISegmentStatus.STARTED,
      );

      const completedSegments = currentDaySegments.filter(
        item => item.broadcastStatus === ISegmentStatus.COMPLETED,
      );
      const pendingSegments = currentDaySegments.filter(
        item => item.broadcastStatus === ISegmentStatus.PENDING,
      );
      const startedSegments = currentDaySegments.filter(
        item => item.broadcastStatus === ISegmentStatus.STARTED,
      );
      // here if zone is disabled or current day segments are zero
      if (!pageSections.Schedule || currentDaySegments.length === 0) {
        if (isBeforeDayOfEvent) {
          return {
            heading: [
              {
                text: `Hi, ${firstName}! Welcome to ${title}`,
                style: IContentTextStyleTypes.BOLDED,
              },
            ],
            subtext: [
              {
                text: 'We’re excited to get started. Please stay tuned for upcoming sessions.',
                style: IContentTextStyleTypes.UNSET,
              },
            ],
          };
        }
        if (isEventCompleted) {
          return {
            heading: [
              {
                text: `That’s a wrap! Thanks for joining us at ${title}`,
                style: IContentTextStyleTypes.BOLDED,
              },
            ],
            subtext: [
              {
                text: 'We hope you had a great time!',
                style: IContentTextStyleTypes.UNSET,
              },
            ],
          };
        }

        if (isBroadcasting) {
          return {
            heading: [
              {
                text: `Thank you for coming, ${firstName}`,
                style: IContentTextStyleTypes.BOLDED,
              },
            ],
            subtext: [
              {
                text: 'Please stay tuned! We’ll begin shortly.',
                style: IContentTextStyleTypes.UNSET,
              },
            ],
          };
        }

        if (!isBroadcasting) {
          return {
            heading: [
              {
                text: `Thank you for joining, ${firstName}`,
                style: IContentTextStyleTypes.BOLDED,
              },
            ],
            subtext: [
              {
                text: 'Stay tuned! The event will go live soon',
                style: IContentTextStyleTypes.UNSET,
              },
            ],
          };
        }
      }

      // after event completed
      if (isEventCompleted) {
        return {
          heading: [
            {
              text: `That’s a wrap! Thanks for joining us at ${title}`,
              style: IContentTextStyleTypes.BOLDED,
            },
          ],
          subtext: [
            {
              text: 'We hope you had a great time!',
              style: IContentTextStyleTypes.UNSET,
            },
          ],
        };
      }

      // Before event starts stage message
      if (isBeforeDayOfEvent) {
        return {
          heading: [
            {
              text: `Hi, ${firstName}! Welcome to ${title}`,
              style: IContentTextStyleTypes.BOLDED,
            },
          ],
          subtext: [
            {
              text: 'We’re excited to get started.',
              style: IContentTextStyleTypes.UNSET,
            },
            {
              text:
                'Please stay tuned and check the event Schedule for more info.',
              style: IContentTextStyleTypes.BOLDED,
            },
          ],
        };
      }

      // when we dont have any pending and running sessions
      if (
        startedSegments.length === 0 &&
        pendingSegments.length === 0 &&
        completedSegments.length >= 0
      ) {
        return {
          heading: [
            {
              text: `Thanks for attending the sessions today, ${firstName}`,
              style: IContentTextStyleTypes.BOLDED,
            },
          ],
          subtext: [
            {
              text: 'Please check the event Schedule for info on the sessions lined up for tomorrow!',
              style: IContentTextStyleTypes.UNSET,
            },
          ],
        };
      }

      // we have completed segment and we have pending segment
      if (completedSegments.length > 0 && pendingSegments.length > 0) {
        return {
          heading: [
            {
              text: `Thank you for attending the session, ${firstName}`,
              style: IContentTextStyleTypes.BOLDED,
            },
          ],
          subtext: [
            {
              text: 'Stay tuned! The next session will begin shortly.',
              style: IContentTextStyleTypes.UNSET,
            },
            {
              text: 'Please check the event Schedule for details on the upcoming session.',
              style: IContentTextStyleTypes.UNSET,
            },
          ],
        };
      }

      // at least one segment started and we have some pending segments
      if (startedSegments.length >= 0 && pendingSegments.length >= 0) {
        return {
          heading: [
            {
              text: `Thank you for joining, ${firstName}`,
              style: IContentTextStyleTypes.BOLDED,
            },
          ],
          subtext: [
            {
              text: 'Stay tuned! The event will go live soon.',
              style: IContentTextStyleTypes.UNSET,
            },
            {
              text: 'For more details, please check the event Schedule.',
              style: IContentTextStyleTypes.BOLDED,
            },
          ],
        };
      }

      // all sessions are completed for total event
      if (
        completedSegmentsFromAllSeg.length >= 0 &&
        pendingSegmentsFromAllSeg.length === 0 &&
        startedSegmentsFromAllSeg.length === 0
      ) {
        return {
          heading: [
            {
              text: `That’s a wrap! Thanks for joining us at ${title}`,
              style: IContentTextStyleTypes.BOLDED,
            },
          ],
          subtext: [
            {
              text: 'We hope you had a great time!',
              style: IContentTextStyleTypes.UNSET,
            },
          ],
        };
      }


      return {
        heading: [
          {
            text: isBroadcasting
              ? `Thanks for coming! ${firstName}`
              : `Thank you for attending the session, ${firstName}`,
            style: IContentTextStyleTypes.BOLDED,
          },
        ],

        subtext: isBroadcasting
          ? [
            {
              text: 'Please stay tuned! We’ll begin shortly.',
              style: IContentTextStyleTypes.UNSET,
            },
          ]
          : [
            {
              text: 'Stay tuned! The next session will begin shortly.',
              style: IContentTextStyleTypes.UNSET,
            },
            {
              text:
                'Please check the event Schedule for details on the upcoming session.',
              style: IContentTextStyleTypes.UNSET,
            },
          ],
      };
    }
    case IEmptyStateParentSources.STAGE_PREVIEW: {
      const { firstName, eventCountdown } = config;
      return {
        heading: [],
        subtext: isBroadcasting
          ? [
              {
                text: 'Stage Preview',
                style: IContentTextStyleTypes.UNSET,
              },
            ]
          : [
              {
                text: 'Stage Preview ',
                style: IContentTextStyleTypes.UNSET,
              },
            ],
      };
    }
    case IEmptyStateParentSources.STAGE_SPEAKER: {
      const { eventCountdown } = config;
      // eslint-disable-next-line
      return {
        heading: [
          {
            text: "We're glad you're here!",
            style: IContentTextStyleTypes.BOLDED,
          },
        ],
        subtext: [
          {
            text: 'Your event will start soon. Until then, you can ',
            style: IContentTextStyleTypes.UNSET,
          },
          {
            text: ' explore Backstage.',
            style: IContentTextStyleTypes.BOLDED,
          },
        ],
      };
    }
    case IEmptyStateParentSources.STAGE_ORGANIZER: {
      // eslint-disable-next-line
      return {
        heading: [
          {
            text: "We're glad you're here!",
            style: IContentTextStyleTypes.BOLDED,
          },
        ],
        subtext: [
          {
            text: 'Your event will start soon. Until then, you can ',
            style: IContentTextStyleTypes.UNSET,
          },
          {
            text: ' explore Backstage or begin the broadcast.',
            style: IContentTextStyleTypes.BOLDED,
          },
        ],
      };
    }
    case IEmptyStateParentSources.BRIEFCASE_EMPTY: {
      return {
        heading: [
          {
            text: 'No Business Cards exchanged',
            style: IContentTextStyleTypes.BOLDED,
          },
        ],
        subtext: [
          {
            text:
              'Business cards exchanged during networking will be available here',
            style: IContentTextStyleTypes.UNSET,
          },
        ],
      };
    }
    default: {
      return {
        heading: [
          {
            text: "We're glad you're here!",
            style: IContentTextStyleTypes.BOLDED,
          },
        ],
        subtext: [],
      };
    }
  }
};
