import React, { useState, useEffect } from 'react';
import soft from 'timezone-soft';
import { parse, setHours, setMinutes } from 'date-fns';
import { utcToZonedTime, format as tzFormat } from 'date-fns-tz';
// components
import FieldLabel from '../FieldLabel';
import Select from 'react-select';
// styles + types
import styles from './styles.module.scss';
import TooltipDiv from '@/components/ui/icons/TooltipDiv';
import DownArrowIcon from '@/components/ui/new-icons/DownArrow';

const DATE_TIME_STRING_FORMAT = "yyyy-MM-dd'T'HH:mm:ssXXX";

const TimePickerField = props => {
  const {
    className = '',
    options = [],
    error,
    label,
    onChange,
    readOnly,
    date,
    value,
    isMandatory,
    tooltip,
    minuteBreak = 15,
    timezone,
  } = props;

  const [meridiemOptions] = useState([
    { label: 'AM', value: 'AM' },
    { label: 'PM', value: 'PM' },
  ]);

  const [selectedHour, setSelectedHour] = useState(null) as any;
  const [selectedMinute, setSelectedMinute] = useState(null) as any;
  const [selectedMeridiem, setSelectedMeridiem] = useState(meridiemOptions[1]);
  const [minuteOptions, setMinuteOptions] = useState([]) as any;
  const [hourOptions, setHourOptions] = useState([]) as any;

  useEffect(() => {
    const hoursOptionsList = [] as any;
    for (let i = 1; i <= 12; i += 1) {
      hoursOptionsList.push({
        label: i < 10 ? `0${i}` : `${i}`,
        value: i,
      });
    }
    setHourOptions(hoursOptionsList);
  }, []);

  useEffect(() => {
    const minuteOptionsList = [] as any;
    for (let i = 0; i < 60; i += minuteBreak) {
      minuteOptionsList.push({
        label: i < 10 ? `0${i}` : `${i}`,
        value: i,
      });
    }
    setMinuteOptions(minuteOptionsList);
  }, []);

  const setTimeValues = dateToUpdate => {
    const hour = Number(selectedHour.value);
    const minutes = Number(selectedMinute.value);
    let hours24 = hour;
    if (hour === 12 && selectedMeridiem.value === 'AM') {
      hours24 = 0;
    } else if (hour !== 12 && selectedMeridiem.value === 'PM') {
      hours24 = hour + 12;
    }

    let newDateTime = setHours(dateToUpdate, hours24);
    newDateTime = setMinutes(newDateTime, minutes);
    return newDateTime;
  };

  const setTimeOptions = sourceDate => {
    const hour = sourceDate.getHours();
    const minute = sourceDate.getMinutes();
    const meridiem = hour >= 12 ? 'PM' : 'AM';
    const hours12 = (sourceDate.getHours() + 24) % 12 || 12;
    setSelectedHour(hourOptions.find(opt => opt.value === hours12));
    setSelectedMinute(minuteOptions.find(opt => opt.value === minute));
    setSelectedMeridiem(meridiemOptions.find(opt => opt.value === meridiem));
  };

  const [dateObject, setDateObject] = useState(null);
  const [dateString, setDateString] = useState(null);

  // Sets the dateObject & dateString on initial load / after save.
  useEffect(() => {
    if (!value || value === dateString) return;

    if (!!value && !!dateString) {
      const oldTime = new Date().getTime();
      const newTime = new Date(dateString).getTime();

      if (oldTime === newTime) return;
    }

    let parsedDate = parse(value, DATE_TIME_STRING_FORMAT, Date.now());
    const isoDate = new Date(parsedDate.toISOString());

    if (!!timezone && !isNaN(isoDate)) {
      parsedDate = utcToZonedTime(isoDate, timezone);
    }
    parsedDate.setSeconds(0);
    setDateObject(parsedDate);
    setTimeOptions(parsedDate);
    // const newDateString = tzFormat(parsedDate, DATE_TIME_STRING_FORMAT, {
    //   timeZone: timezone,
    // });
    // if (dateString !== newDateString) {
    //   // setDateString(newDateString);
    // }
  }, [value, timezone]);

  // Sets the dateObject & dateString on change of associated date from props.
  useEffect(() => {
    if (!date) return;

    let isoDate = new Date(date.toISOString());
    isoDate.setSeconds(0);
    setDateObject(isoDate);

    // if (!!timezone && !isNaN(isoDate)) {
    //   parsedDate = utcToZonedTime(isoDate, timezone);
    // }

    // if (!dateObject || (
    //   parsedDate.getDate() !== dateObject.getDate() ||
    //   parsedDate.getMonth() !== dateObject.getMonth() ||
    //   parsedDate.getFullYear() !== dateObject.getFullYear()
    // )) {
    //   if (!!selectedMeridiem && !!selectedMinute && !!selectedHour) {
    //     parsedDate = setTimeValues(parsedDate);
    //   }
    //   parsedDate.setSeconds(0);

    //   setDateObject(parsedDate);
    //   const newDateString = tzFormat(parsedDate, DATE_TIME_STRING_FORMAT, {
    //     timeZone: timezone,
    //   });
    //   if (dateString !== newDateString) {
    //     setDateString(newDateString);
    //     // onChange(newDateString);
    //   }
    // }
  }, [date, timezone]);

  const [ianaCode, setIANACode] = useState(null);
  useEffect(() => {
    if (!timezone) return;

    const displayedValues = soft(timezone)[0];
    if (displayedValues?.standard) {
      setIANACode(displayedValues.standard.abbrev);
    }
  }, [timezone]);

  // Update time select changes
  useEffect(() => {
    if (!selectedHour || !selectedMinute || !selectedMeridiem) return;
    if (!dateObject && !date) return;

    const updatedDateTime = setTimeValues(dateObject || date);
    setDateObject(updatedDateTime);
    // setDateString(format(updatedDateTime, DATE_TIME_STRING_FORMAT));
    const newDateString = tzFormat(updatedDateTime, DATE_TIME_STRING_FORMAT, {
      timeZone: timezone,
    });
    if (dateString !== newDateString) {
      setDateString(newDateString);
    }
  }, [selectedHour, selectedMinute, selectedMeridiem]);

  useEffect(() => {
    if (!dateString) return;

    if (onChange && dateString !== value) {
      if (!!value && !!dateString) {
        const oldTime = new Date(value).getTime();
        const newTime = new Date(dateString).getTime();

        if (oldTime === newTime) return;
      }
      onChange(dateString);
    }
  }, [dateString]);

  return (
    <div className={styles.container}>
      <div className={styles.titleLabelContainer}>
        {label && label.length ? <FieldLabel value={label} /> : null}
        {!!ianaCode && (
          <>
            &nbsp;
            <FieldLabel value={`(In ${ianaCode})`} />
          </>
        )}
        {isMandatory && <FieldLabel value="*" />}
        {label && label.length && tooltip && (
          <TooltipDiv
            dataFor={`tooltip_time_picker_${label}`}
            tooltipText={tooltip}
          />
        )}
      </div>
      <div className={styles.selectGroup}>
        {/* Hour Select */}
        <Select
          placeholder="Hour"
          className={styles.selectField}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: () => (
              <>
                <div style={{ marginRight: '11px' }}>
                  <DownArrowIcon />
                </div>
                <div className={styles.seperator} />
              </>
            ),
          }}
          options={hourOptions}
          value={[selectedHour]}
          onChange={setSelectedHour}
          styles={{
            menuList: (provided, state) => ({
              ...provided,
              height: 120,
            }),
          }}
        />
        {/* Minutes Select */}
        <Select
          placeholder="Mins"
          className={styles.selectField}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: () => (
              <>
                <div style={{ marginRight: '11px' }}>
                  <DownArrowIcon />
                </div>
                <div className={styles.seperator} />
              </>
            ),
          }}
          options={minuteOptions}
          value={[selectedMinute]}
          onChange={setSelectedMinute}
          styles={{
            menuList: (provided, state) => ({
              ...provided,
              height: 120,
            }),
          }}
        />
        {/* Meridiem Select */}
        <Select
          placeholder="PM"
          className={styles.selectField}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: () => (
              <div style={{ marginRight: '11px' }}>
                <DownArrowIcon />
              </div>
            ),
          }}
          options={meridiemOptions}
          value={[selectedMeridiem]}
          onChange={setSelectedMeridiem}
        />
      </div>
      {/* { error && <FormValidationErrorTooltip value={error} />} */}
      {error && <div className={styles.customErrorDisplay}>{error}</div>}
    </div>
  );
};

export default React.memo(TimePickerField);
