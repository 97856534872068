import { IGeneralSizeTypes } from '@/types';

export interface IProfilePictureProps {
  hexColor: string;
  iconSize?: IGeneralSizeTypes;
  initials?: string;
  imageUrl?: string;
  name?: string;
  styleClass?: string;
  className?: string;
  frame?: IProfilePictureFrames;
  isLive?: boolean;
  disableTooltip?: boolean;
  headline?: string;
}

export enum IProfilePictureFrames {
  ROUND_BUBBLE_PRIMARY_BOTTOM = 'roundBubblePrimaryFrameBottom',
  ROUND_BUBBLE_PRIMARY_TOP = 'roundBubblePrimaryFrameTop',
  ROUND_BUBBLE_WHITE_BOTTOM = 'roundBubbleWhiteFrameBottom',
  ROUND_BUBBLE_WHITE_TOP = 'roundBubbleWhiteFrameTop',
}
