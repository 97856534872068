import React from 'react'
import classnames from 'classnames';

import styles from './styles.module.scss';

export const ToolbarButton = React.forwardRef(
  ({ className, active, ...props }, ref) => (
    <span
      {...props}
      ref={ref}
      className={classnames(styles.toolbarButton, active ? styles.activeButton : '')}
    />
  )
);

export const Menu = React.forwardRef(({ className, ...props }, ref) => (
  <div
    {...props}
    ref={ref}
    className={classnames(
      className,
      styles.menu
    )}
  />
));

export const Toolbar = React.forwardRef(({ className, ...props }, ref) => (
  <Menu
    {...props}
    ref={ref}
    className={classnames(
      className,
      styles.toolbar
    )}
  />
));
