import React, { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
// components
import LoaderCompo from '@/components/ui/Loader';
import Button, { IButtonTypes } from '@/components/ui/buttons/Button';
import NewLabel, {
  INewLabelMargins,
  INewLabelColors,
  INewLabelStyles,
} from '@/components/ui/content/NewLabel';
import KeynoteIcon from '@/components/ui/icons/KeynoteIcon';
import {
  IContentColors,
  IContentTextMarginTypes,
  IContentTextSizeTypes,
  IUIIcons,
} from '@/types';
import {
  ArrowLeft,
  ArrowUpRight,
  Award,
  Cast,
  LogIn,
  LogOut,
  Mic,
  MicOff,
  Phone,
  Users,
  Video,
  VideoOff,
  XSquare,
  Anchor,
  Camera,
  Download,
  VolumeX,
  Volume2,
  Search,
  ArrowRight,
  ChevronLeft,
  Check,
  Loader,
  ArrowUp,
  Filter,
  Calendar,
  MessageCircle,
  ChevronRight,
  Wifi,
  Eye,
  RefreshCcw,
} from 'react-feather';
// styles + types
import { SMALL_ICON_SIZE, EMOJI_ICON_SIZE } from '@/styles/iconSizes';
import styles from './styles.module.scss';
import { IIconTextButtonProps } from './types';
import { getCssVar } from '@/utils/cssVars';
import UserPlusIcon from '../../new-icons/UserPlus';
import UploadIcon from '../../new-icons/Upload';
import TimerLineIcon from '../../new-icons/TimerLine';
import StopIcon from '../../new-icons/Stop';
import SignalTowerIcon from '../../new-icons/SignalTower';
import AsteriskIcon from '../../new-icons/Asterisk';
import StartStream from '../../new-icons/StartStream';
import StopStream from '../../new-icons/StopStream';
import DoubleTick from '../../new-icons/DoubleTick';
import FilterBar from '../../new-icons/FilterBar';
import BroadcastStart from '../../new-icons/BroadcastStart';
import BroadcastStop from '../../new-icons/BroadcastStop';
import BroadcastPause from '../../new-icons/BroadcastPause';
import BroadcastResume from '../../new-icons/BroadcastResume';
import Chrome from '../../new-icons/Chrome';
import Edge from '../../new-icons/Edge';
import Browser from '../../new-icons/Browser';
import CloseCircleFilled from '../../new-icons/CloseCircleFilled';
import UploadFilledIcon from '../../new-icons/UploadFilled';
import BarChartFilled from '../../new-icons/BarChartFilled';
import Brain from '../../new-icons/Brain';
import Safari from '../../new-icons/Safari';
import Bin from '../../new-icons/Bin';
import CopyIcon from '../../new-icons/Copy';
import DuplicateFilledIcon from '../../new-icons/DuplicateFilled';
import AddCircleFilled from '../../new-icons/AddCircleFilled';
import KeyFilled from '../../new-icons/KeyFilled';
import TicketIcon from '../../new-icons/Ticket';
import ReactTooltip from 'react-tooltip';
import { TooltipTypes } from '../IconButton/types';
import AddIcon from '../../new-icons/Add';
import ScreenShareOn from '../../new-icons/ScreenShareOn';

const {
  BACK_NAVIGATION,
  BROADCAST_START,
  BROADCAST_STOP,
  EXPO,
  LOBBY,
  KEYNOTE,
  MIC_OFF,
  MIC,
  SHARE_SCREEN_OFF,
  SHARE_SCREEN,
  STREAM_JOIN,
  STREAM_LEAVE,
  TABLE_SWITCH,
  USERS,
  VIDEO_OFF,
  VIDEO,
  CAMERA,
  DOWNLOAD,
  VOLUME_OFF,
  VOLUME_ON,
  FORWARD_NAVIGATION,
  SEARCH,
  CHEVRON_LEFT,
  USER_PLUS,
  CHECK,
  LOADER,
  UPLOAD,
  TIMER_LINE,
  STOP,
  SIGNAL_TOWER,
  ASTERISK,
  STARTSTREAM,
  STOPSTREAM,
  DOUBLE_TICK,
  ARROW_UP,
  FEATHER_FILTER,
  FEATHER_CALENDAR,
  FEATHER_MESSAGE_CIRCLE,
  CHEVRON_RIGHT,
  FILTER_BAR,
  NEW_BROADCAST_START,
  NEW_BROADCAST_STOP,
  NEW_BROADCAST_PAUSE,
  NEW_BROADCAST_RESUME,
  NEW_STREAM_LEAVE,
  LEAVE_EXPO,
  CHROME_BROWSER,
  EDGE_BROWSER,
  BROWSER,
  WIFI,
  EYE,
  CLOSE_CIRCLE_FILLED,
  UPLOAD_FILLED,
  REFRESH,
  BAR_CHART_FILLED,
  BRAIN,
  SAFARI_BROWSER,
  BIN,
  COPY,
  DUPLICATE,
  PLUS_CIRCLE_FILLED,
  KEY_FILLED,
  TICKET,
  ADD,
  SCREEN_SHARE_ON,
} = IUIIcons;

const IconTextButton = (props: IIconTextButtonProps) => {
  const {
    ref,
    activeIcon,
    activeLabel = '',
    activeLabelStyle = INewLabelStyles.DEFAULT,
    defaultIcon,
    defaultLabel = '',
    defaultLabelStyle = INewLabelStyles.DEFAULT,
    disabled,
    showDefault,
    onClick,
    buttonType = IButtonTypes.PRIMARY,
    buttonMargin = IContentTextMarginTypes.NO_MARGIN,
    iconLast = false,
    iconSize = SMALL_ICON_SIZE,
    buttonIconColor = '',
    style = {},
    hasHover = false,
    isloader = false,
    labelSize = IContentTextSizeTypes.DEFAULT,
    showDivider = false,
    id,
    defaultIconTooltip,
    activeIconTooltip,
    tooltipPosition = TooltipTypes.top,
  } = props;

  const iconColor = (() => {
    if (buttonIconColor) {
      return buttonIconColor;
    }
    if (buttonType === IButtonTypes.DANGER_NO_BG) {
      return getCssVar(IContentColors.DANGER);
    }
    if (buttonType === IButtonTypes.NEW_SECONDARY) {
      return getCssVar(IContentColors.PRIMARY);
    }
    if (buttonType === IButtonTypes.NTWRK_OUTLINED_DANGER_SQUARE) {
      return getCssVar(IContentColors.DANGER);
    }
    if (buttonType === IButtonTypes.NTWRK_OUTLINED_SUCCESS_SQUARE) {
      return getCssVar(IContentColors.SUCCESS);
    }
    if (buttonType === IButtonTypes.NTWRK_SUCCESS_SQUARE) {
      return getCssVar(IContentColors.BLACK);
    }
    if (buttonType === IButtonTypes.NTWRK_DANGER_SQUARE) {
      return getCssVar(IContentColors.BLACK);
    }
    if (
      buttonType === IButtonTypes.BCKSTG_DANGER_SQUARE ||
      buttonType === IButtonTypes.BCKSTG_SUCCESS_SQUARE
    ) {
      return getCssVar(IContentColors.MONOCHROME_CONTRAST);
    }
    if (buttonType === IButtonTypes.COLOURS_NEUTRAL_BASE_3) {
      return getCssVar(IContentColors.NEUTRAL_CONTRAST_3);
    }
    if (buttonType === IButtonTypes.COLOURS_NEUTRAL_BASE_4) {
      return getCssVar(IContentColors.NEUTRAL_CONTRAST_4);
    }
    if (buttonType === IButtonTypes.COLOURS_NEUTRAL_BASE_1) {
      return getCssVar(IContentColors.NEUTRAL_MID_3);
    }
    if (buttonType === IButtonTypes.COLOURS_MONOCHROME_CONTRAST) {
      return getCssVar(IContentColors.MONOCHROME_BASE);
    }
    if (buttonType === IButtonTypes.COLOURS_MONOCHROME_CONTRAST_OUTLINED) {
      return getCssVar(IContentColors.MONOCHROME_CONTRAST);
    }
    if (buttonType === IButtonTypes.QUESTION_ANSWERED) {
      return getCssVar(IContentColors.SUCCESS);
    }
    if (buttonType === IButtonTypes.COLOURS_NEUTRAL_MID_1) {
      return getCssVar(IContentColors.MONOCHROME_CONTRAST);
    }
    if (buttonType === IButtonTypes.BCKSTG_SUCCESS_SQUARE_RADIUS) {
      return getCssVar(IContentColors.WHITE);
    }
    if (buttonType === IButtonTypes.BCKSTG_DANGER_SQUARE_RADIUS) {
      return getCssVar(IContentColors.DANGER);
    }
    if (buttonType === IButtonTypes.NEW_PRIMARY) {
      return getCssVar(IContentColors.SECONDARY);
    }
    if (buttonType === IButtonTypes.SNACK_BAR_BUTTON_PRIMARY) {
      return getCssVar(IContentColors.SECONDARY);
    }

    return getCssVar(IContentColors.SECONDARY);
  })();

  const labelColor = (() => {
    if (buttonType === IButtonTypes.COLOURS_NEUTRAL_BASE_1) {
      return INewLabelColors.NEUTRAL_MEDIUM;
    }
    if (buttonType === IButtonTypes.COLOURS_MONOCHROME_CONTRAST) {
      return INewLabelColors.MONOCHROME_BASE;
    }
    if (
      buttonType === IButtonTypes.COLOURS_MONOCHROME_CONTRAST_OUTLINED ||
      buttonType === IButtonTypes.BCKSTG_SUCCESS_SQUARE
    ) {
      return INewLabelColors.MONOCHROME_CONTRAST;
    }
    if (
      buttonType === IButtonTypes.NEW_DANGER ||
      buttonType === IButtonTypes.NEW_SUCCESS_SECONDARY ||
      buttonType === IButtonTypes.SUCCESS_PRIMARY ||
      buttonType === IButtonTypes.DANGER_PILL_RIGHT ||
      buttonType === IButtonTypes.SUCCESS_PILL_RIGHT ||
      buttonType === IButtonTypes.DANGER_PRIMARY
    ) {
      return INewLabelColors.WHITE;
    }
    if (buttonType === IButtonTypes.QUESTION_ANSWERED) {
      return INewLabelColors.WHITE;
    }
    if (buttonType === IButtonTypes.COLOURS_NEUTRAL_MID_1) {
      return INewLabelColors.MONOCHROME_CONTRAST;
    }
    if (buttonType === IButtonTypes.COLOURS_NEUTRAL_BASE_3) {
      return INewLabelColors.MONOCHROME_CONTRAST;
    }
    if (buttonType === IButtonTypes.COLOURS_NEUTRAL_BASE_4) {
      return INewLabelColors.MONOCHROME_CONTRAST;
    }
    if (
      buttonType === IButtonTypes.BCKSTG_DANGER_SQUARE_RADIUS ||
      buttonType === IButtonTypes.NTWRK_OUTLINED_DANGER_SQUARE) {
      return INewLabelColors.DANGER;
    }
    if (
      buttonType === IButtonTypes.NEW_PRIMARY ||
      IButtonTypes.NEW_SNACK_BAR_BUTTON_PRIMARY ||
      IButtonTypes.SNACK_BAR_BUTTON_PRIMARY
    ) {
      return INewLabelColors.SECONDARY;
    }
    return INewLabelColors.WHITE;
  })();

  const iconMap = {
    [BACK_NAVIGATION]: <ArrowLeft color={iconColor} size={EMOJI_ICON_SIZE} />,
    [BROADCAST_START]: <Cast color={iconColor} size={iconSize} />,
    [BROADCAST_STOP]: (
      <Phone className={styles.endCallIcon} color={iconColor} size={iconSize} />
    ),
    [EXPO]: <Award color={iconColor} size={iconSize} />,
    [KEYNOTE]: <KeynoteIcon />,
    [LOBBY]: <Anchor color={iconColor} size={iconSize} />,
    [MIC_OFF]: <MicOff color={iconColor} size={iconSize} />,
    [MIC]: <Mic color={iconColor} size={iconSize} />,
    [SHARE_SCREEN_OFF]: <XSquare color={iconColor} size={iconSize} />,
    [SHARE_SCREEN]: <Cast color={iconColor} size={iconSize} />,
    [STREAM_JOIN]: <LogIn color={iconColor} size={iconSize} />,
    [STREAM_LEAVE]: (
      <LogOut
        className={styles.streamLeaveIcon}
        color={iconColor}
        size={iconSize}
      />
    ),
    [VIDEO_OFF]: <VideoOff color={iconColor} size={iconSize} />,
    [VIDEO]: <Video color={iconColor} size={iconSize} />,
    [TABLE_SWITCH]: <ArrowUpRight color={iconColor} size={iconSize} />,
    [USERS]: <Users color={iconColor} size={iconSize} />,
    [CAMERA]: <Camera color={iconColor} size={iconSize} />,
    [DOWNLOAD]: <Download color={iconColor} size={iconSize} />,
    [VOLUME_OFF]: <VolumeX color={iconColor} size={iconSize} />,
    [VOLUME_ON]: <Volume2 color={iconColor} size={iconSize} />,
    [FORWARD_NAVIGATION]: (
      <ArrowRight color={iconColor} size={iconSize} strokeWidth={3} />
    ),
    [SEARCH]: <Search color={iconColor} size={iconSize} />,
    [CHEVRON_LEFT]: <ChevronLeft color={iconColor} size={iconSize} />,
    [USER_PLUS]: <UserPlusIcon color={iconColor} size={iconSize} />,
    [CHECK]: <Check color={iconColor} size={iconSize} strokeWidth={3} />,
    [LOADER]: <Loader color={iconColor} size={iconSize} />,
    [UPLOAD]: <UploadIcon color={iconColor} size={iconSize} />,
    [TIMER_LINE]: <TimerLineIcon color={iconColor} size={iconSize} />,
    [STOP]: <StopIcon color={iconColor} size={iconSize} />,
    [SIGNAL_TOWER]: <SignalTowerIcon color={iconColor} size={iconSize} />,
    [ASTERISK]: <AsteriskIcon color={iconColor} size={iconSize} />,
    [STARTSTREAM]: <StartStream color={iconColor} size={iconSize} />,
    [STOPSTREAM]: <StopStream color={iconColor} size={iconSize} />,
    [DOUBLE_TICK]: <DoubleTick color={iconColor} size={iconSize} />,
    [ARROW_UP]: <ArrowUp color={iconColor} size={iconSize} strokeWidth={3} />,
    [FEATHER_FILTER]: <Filter color={iconColor} size={iconSize} />,
    [FEATHER_CALENDAR]: <Calendar color={iconColor} size={iconSize} />,
    [FEATHER_MESSAGE_CIRCLE]: (
      <MessageCircle color={iconColor} size={iconSize} />
    ),
    [CHEVRON_RIGHT]: <ChevronRight color={iconColor} size={iconSize} />,
    [FILTER_BAR]: <FilterBar color={iconColor} size={iconSize} />,
    [NEW_BROADCAST_START]: <BroadcastStart size={iconSize} />,
    [NEW_BROADCAST_STOP]: <BroadcastStop size={iconSize} />,
    [NEW_BROADCAST_PAUSE]: <BroadcastPause size={iconSize} />,
    [NEW_BROADCAST_RESUME]: <BroadcastResume size={iconSize} />,
    [NEW_STREAM_LEAVE]: <ArrowLeft color={iconColor} size={iconSize} />,
    [LEAVE_EXPO]: <LogOut color={iconColor} size={iconSize} />,
    [EYE]: <Eye size={iconSize} />,
    [WIFI]: <Wifi size={iconSize} />,
    [BROWSER]: <Browser size={iconSize} />,
    [EDGE_BROWSER]: <Edge size={iconSize} />,
    [CHROME_BROWSER]: <Chrome size={iconSize} />,
    [CLOSE_CIRCLE_FILLED]: <CloseCircleFilled size={iconSize} />,
    [UPLOAD_FILLED]: <UploadFilledIcon color={iconColor} size={iconSize} />,
    [REFRESH]: <RefreshCcw color={iconColor} size={iconSize} />,
    [BAR_CHART_FILLED]: <BarChartFilled size={iconSize} />,
    [BRAIN]: <Brain size={iconSize} />,
    [SAFARI_BROWSER]: <Safari size={iconSize} />,
    [BIN]: <Bin color={iconColor} size={iconSize} />,
    [COPY]: <CopyIcon color={iconColor} size={iconSize} />,
    [DUPLICATE]: <DuplicateFilledIcon color={iconColor} size={iconSize} />,
    [PLUS_CIRCLE_FILLED]: <AddCircleFilled size={iconSize} />,
    [KEY_FILLED]: <KeyFilled size={iconSize} color={iconColor} />,
    [TICKET]: <TicketIcon size={iconSize} color={iconColor} />,
    [ADD]: <AddIcon size={iconSize} color={iconColor} />,
    [SCREEN_SHARE_ON]: <ScreenShareOn size={iconSize} color={iconColor} />,
  };

  const icon = showDefault ? iconMap[defaultIcon] : iconMap[activeIcon];
  const label = showDefault ? defaultLabel : activeLabel;
  const labelStyle = showDefault ? defaultLabelStyle : activeLabelStyle;

  const handleClick = e => {
    if (disabled || isloader) {
      return;
    }
    e.stopPropagation();
    onClick && onClick(e);
  };

  const uuid = useMemo(() => uuidv4(), []);

  const tooltipProps = (() => {
    if (tooltipPosition === TooltipTypes.none) {
      return {};
    }

    if (
      defaultIcon === IUIIcons.TICKET ||
      defaultIcon === IUIIcons.KEY_FILLED
    ) {
      return {
        'data-place': tooltipPosition,
        'data-tip': 'tooltip',
      };
    }

    return {
      'data-place': tooltipPosition,
      'data-tip': 'tooltip',
    };
  })();

  return (
    <Button
      id={id}
      ref={ref}
      label={label}
      disabled={disabled || isloader}
      margin={buttonMargin}
      onClick={onClick}
      styleClass={buttonType}
      type="button"
      reverseChildren={iconLast}
      style={style}
      hasHover={hasHover}
      tooltipFor={`icon-text-button-${
        showDefault ? 'default' : 'active'
      }-tooltip-${uuid}`}
      tooltipProps={tooltipProps}
    >
      {isloader && <LoaderCompo />}
      {!showDivider && icon}
      {showDivider && (
        <div className={styles.iconWithDivider}>
          <div className={styles.divider}></div>
          {icon}
        </div>
      )}
      {defaultIconTooltip && (
        <div className={styles.tooltipParent}>
          <ReactTooltip
            id={`icon-text-button-default-tooltip-${uuid}`}
            effect="solid"
          >
            {defaultIconTooltip}
          </ReactTooltip>
        </div>
      )}
      {activeIconTooltip && (
        <div className={styles.tooltipParent}>
          <ReactTooltip
            id={`icon-text-button-active-tooltip-${uuid}`}
            effect="solid"
          >
            {activeIconTooltip}
          </ReactTooltip>
        </div>
      )}
      <NewLabel
        text={label}
        labelMargin={
          iconLast
            ? INewLabelMargins.SMALL_MARGIN_RIGHT
            : INewLabelMargins.SMALL_MARGIN_LEFT
        }
        onClick={onClick ? handleClick : null}
        labelColor={labelColor}
        labelStyle={labelStyle}
        labelSize={labelSize}
        readOnly={disabled || isloader}
      />
    </Button>
  );
};

export default IconTextButton;
export {
  IUIIcons,
  IButtonTypes as IIconTextButtonTypes,
  IContentTextMarginTypes as IIconTextButtonMarginTypes,
  INewLabelStyles as IIconTextButtonLabelStyles,
};
