import React, { useEffect, useRef, useState } from 'react';
import { components } from 'react-select';
// components
import FieldLabel from '../FieldLabel';
// styles + types
import styles from './styles.module.scss';
import classnames from 'classnames';
import FormValidationErrorTooltip from '../../FormValidationErrorTooltip';
import TooltipDiv from '@/components/ui/icons/TooltipDiv';
import SelectField from '../SelectField';
import { getCountriesCodeOption } from './config';

const InputField = props => {
  const {
    containerStyles,
    labelStyles,
    fieldStyles = {},
    error,
    label,
    onChange,
    onBlur,
    placeholder,
    readOnly,
    value,
    submitFailed,
    isLastElement,
    isMandatory,
    maxLength,
    tooltip,
    hideMaxCharacterLabel = false,
    inputType = 'text',
    showLabelContainer = true,
    minValue = 0,
    step = 1,
    acceptDecimal = false,
    tooltipPlace = 'top',
    dataTestId = "",
  } = props;

  const countriesList = getCountriesCodeOption();
  const [countryCodeValue, setCountryCodeValue] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const validationError = (() => {
    if (error && submitFailed === undefined) {
      // with out Form Section
      return true;
    }
    if (error && submitFailed === true) {
      // with Form Section
      return true;
    }
    return false;
  })();

  const containerWithValidationMessage = classnames(styles.inputField, {
    [styles.addMarginRight]: !isLastElement && !!error,
  });

  const fieldRef = useRef();
  const [showMaxLengthExceededError, setShowMaxLengthExceededError] = useState(
    false,
  );

  useEffect(() => {
    if (!fieldRef.current) return;
    // To reflect data changes into the field text value.
    if (value === undefined) {
      fieldRef.current.value = '';
      return;
    }
    if (fieldRef.current.value !== value || inputType === 'phoneNumber') {
      if(inputType === 'phoneNumber' && value && value.startsWith('+')){
        const code = value.split('-')[0];
        const selectedOption = countriesList.find(option => value.startsWith(option.value));
        if(selectedOption){
          setCountryCodeValue(selectedOption);
          fieldRef.current.value = value.replace(`${selectedOption.value}-`, '');
        }
        else {
          fieldRef.current.value = value;
        }
      } else {
        fieldRef.current.value = value;
      }
    }
  }, [value, inputType]);

  const handleChangeInput = event => {
    const { value } = event.target;
    setShowMaxLengthExceededError(
      maxLength !== undefined && maxLength === value.length,
    );
    
    if(inputType === 'phoneNumber'){
      onChange(
        {
          target: {
            value: countryCodeValue ? `${countryCodeValue?.value}-${value}` : value,
          },
        }
      )
    } else {
      onChange(event);
    }

  };

  // this method accepts only numbers and decimal if input type is number
  const handleKeypress = e => {
    if (inputType === 'number') {
      // 48 - 57 keyCodes for : 0 - 9
      // 190 keyCode for dot(.)
      // 37 keyCode for left arrow key
      // 39 keyCode for right arrow key
      // 8 keyCode for delete
      if (
        (e.keyCode >= 48 && e.keyCode <= 57) ||
        e.keyCode === 8 ||
        e.keyCode === 37 ||
        e.keyCode === 39
      ) {
        return;
      }
      if (e.keyCode === 190 && acceptDecimal) {
        return;
      }
      e.preventDefault();
    }
  };

  const countryCodeValueOnChange = (option) => {
    setCountryCodeValue(option);
    onChange(
      {
        target: {
          value: `${option.value}-${fieldRef.current.value}`,
        },
      }

    )
  }

  const customStyles = {
    menu: provided => ({
      ...provided,
      width: '300px',
      zIndex: 9999,
    }),
    valueContainer: provided => ({
      ...provided,
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '23px',
      width: '20px',
    }),
  }

  const Option = (props) => {
    const { label } = props;
    return (
      <components.Option {...props}>
        {label}
      </components.Option>
    );
  };
  
  const SingleValue = (props) => {
    const { value } = props.data;
    return (
      <components.SingleValue {...props}>
        {value}
      </components.SingleValue>
    );
  };

  const getInputField = () => {
    return (
      <input
        className={classnames(containerWithValidationMessage, fieldStyles, {
          [styles.inputFieldError]: error,
        })}
        defaultValue={value || ''}
        disabled={readOnly}
        onChange={handleChangeInput}
        onBlur={onBlur}
        placeholder={placeholder}
        tabIndex={0}
        maxLength={maxLength}
        type={inputType === 'phoneNumber' ? 'number' : inputType}
        ref={fieldRef}
        styles={fieldStyles}
        min={minValue}
        step={step}
        onKeyDown={handleKeypress}
        data-testid={dataTestId}
      />
    )
  }

  return (
    <div className={classnames(styles.container, containerStyles)}>
      {showLabelContainer && (
        <div className={styles.titleLabelContainer}>
          {label && label.length ? (
            <FieldLabel value={label} className={labelStyles} />
          ) : null}
          {isMandatory && <FieldLabel value="*" className={labelStyles} />}
          {maxLength && !hideMaxCharacterLabel && (
            <FieldLabel
              value={`(Max ${maxLength} characters)`}
              className={
                labelStyles && labelStyles === 'whiteLabel'
                  ? 'maxLengthWhiteLable'
                  : 'maxLengthLable'
              }
            />
          )}
          {label && label.length && tooltip ? (
            <TooltipDiv
              dataFor={`tooltip_input_${label}`}
              tooltipText={tooltip}
              tooltipPlace={tooltipPlace}
            />
          ) : null}
        </div>
      )}

      {inputType === 'phoneNumber' ? (
        <div className={styles.countryCodeContainer}>
          <SelectField 
              componentsContainer={{ Option, SingleValue }}
              customStyles={customStyles}
              containerStyles = {'ticketMappingField'}
              arrowMarginRight={6} 
              value={countryCodeValue} 
              options={countriesList} 
              onChange={countryCodeValueOnChange}
              countryCodeField={true}
            />
            {getInputField()}
        </div>) : (
          getInputField()
        )}

      {maxLength && !hideMaxCharacterLabel && !!value && (
        <div
          className={
            labelStyles && labelStyles === 'whiteLabel'
              ? styles.textLimitWhite
              : styles.textLimit
          }
        >
          {value.length}/{maxLength} characters
        </div>
      )}
      {error && <FormValidationErrorTooltip value={error} />}
      {/* {showMaxLengthExceededError && <FieldLabel value={'Max Character Limit Reached!'} className={"maxLimitExceededText"} />} */}
    </div>
  );
};

export default React.memo(InputField);
