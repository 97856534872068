import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';
// components
import Image from '@/components/ui/media/Image';
// styles + types
import styles from './styles.module.scss';
import { IProfilePictureProps, IProfilePictureFrames } from './types';
import { getFirstNameLastName, getNameInitial } from '@/utils/helpers';
import { IGeneralSizeTypes } from '@/types';
import { getCssVar } from '@/utils/cssVars';
import { useMediaQuery } from 'react-responsive';

const ProfilePicture = (props: IProfilePictureProps) => {
  const {
    frame = '',
    className,
    hexColor,
    iconSize = IGeneralSizeTypes.DEFAULT,
    imageUrl,
    name = '',
    styleClass = '',
    isLive = 0,
    disableTooltip = false,
    headline,
  } = props;
  const [profileInitials, setProfileInitials] = useState('');
  const [firstName, lastName] = getFirstNameLastName(name);
  const firstInitial = getNameInitial(firstName);
  const lastInitial = getNameInitial(lastName);
  let profileLastName = lastName || '';
  let profileHeadLine = headline || '';
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  useEffect(() => {
    if (!name) {
      return;
    }
    setProfileInitials(`${firstInitial}${lastInitial}`);
  }, [name]);

  if (imageUrl) {
    return (
      <div
        data-for={'tooltip-live-navbar-profile-name'}
        data-tip={`${firstName} ${profileLastName} ${
          profileHeadLine != '' ? `- ${profileHeadLine}` : ''
        }`}
      >
        <Image src={imageUrl} styleClass={styleClass} alt={`Profile photo of ${firstName} ${lastName}`} />
        {!disableTooltip && (
          <ReactTooltip
            id={'tooltip-live-navbar-profile-name'}
            className={styles.tooltip}
            arrowColor={getCssVar(hexColor)}
            place="top"
            effect="solid"
          ></ReactTooltip>
        )}
      </div>
    );
  }

  return (
    <div
    tabIndex={0}
    aria-label={`Profile photo of ${firstName} ${lastName}`}
      style={{ backgroundColor: hexColor }}
      className={classnames(
        styles[styleClass],
        styles[iconSize],
        className,
        styles.profilePicture,
        { [`${styles[frame]}`]: frame,
        [styles.singleLine]: isMobile
       },
      )}
      data-for={'tooltip-live-navbar-profile-name'}
      data-tip={`${firstName} ${profileLastName} ${
        profileHeadLine != '' ? `- ${profileHeadLine}` : ''
      }`}
    >
      {isLive === true || isLive === 1 ? (
        <div className={classnames(styles.blink)}></div>
      ) : null}
      {profileInitials}
      {!disableTooltip && (
        <ReactTooltip
          id={'tooltip-live-navbar-profile-name'}
          className={styles.tooltip}
          arrowColor={getCssVar(hexColor)}
          place="top"
          effect="solid"
        ></ReactTooltip>
      )}
    </div>
  );
};

export default ProfilePicture;
export { IProfilePictureFrames };
