import { MEDIUM_ICON_SIZE } from '@/styles/iconSizes';
import React from 'react';
import { useMedia } from 'react-use';
import IconButton, {
  IIconButtonBGColors,
  IIconButtonTypes,
  IUIIcons,
} from '../buttons/IconButton';
import styles from './styles.module.scss';
import { speakers } from './config';
const MockSpeakerList = ({ isWebinar }) => {
  const {
    NEW_MIC_ON,
    NEW_MIC_OFF,
    NEW_VIDEO_OFF,
    NEW_VIDEO_ON,
    SCREEN_SHARE_ON,
    NEW_SCREEN_SHARE_OFF,
  } = IUIIcons;
  const isMobile = useMedia('(max-width: 767px)');
  const speakerList = !isMobile
    ? speakers
    : speakers.filter((_s, idx) => idx <= 1);
  return (
    <>
      <div
        className={styles.speakerList}
        data-tooltip="Interactions are disabled because this is a mock speakers list meant for preview"
      >
        {speakerList.map(img => (
          <div className={styles.speakerBox}>
            <img src={img} alt="speaker" />
          </div>
        ))}
      </div>
      {!isWebinar && (
        <div className={styles.bottomOptionsBar}>
          <div className={styles.bottomCenterStreamOptions}>
            <IconButton
              key="micButton"
              activeIcon={NEW_MIC_OFF}
              defaultIcon={NEW_MIC_ON}
              onClick={() => {}}
              type={IIconButtonTypes.ROUND_THEME}
              iconBG={IIconButtonBGColors.NEUTRAL_LIGHT_3}
              iconSize={MEDIUM_ICON_SIZE}
              tooltip={isMobile ? 'left' : 'top'}
            />
            <IconButton
              key="videoButton"
              activeIcon={NEW_VIDEO_OFF}
              defaultIcon={NEW_VIDEO_ON}
              onClick={() => {}}
              disabled={true}
              type={IIconButtonTypes.ROUND_THEME}
              iconBG={IIconButtonBGColors.NEUTRAL_LIGHT_3}
              iconSize={MEDIUM_ICON_SIZE}
            />
            <IconButton
              key="videoButton"
              activeIcon={SCREEN_SHARE_ON}
              defaultIcon={NEW_SCREEN_SHARE_OFF}
              onClick={() => {}}
              showDefault={true}
              type={IIconButtonTypes.ROUND_THEME}
              iconBG={IIconButtonBGColors.NEUTRAL_LIGHT_3}
              iconSize={MEDIUM_ICON_SIZE}
            />
          </div>
        </div>
      )}
    </>
  );
};
export default MockSpeakerList;
