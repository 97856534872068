export enum IEmptyStateBGTypes {
  DEFAULT = 'default',
  LIGHT_OPACITY = 'lightOpacity',
  DARK_OPACITY = 'darkOpacity',
};

export enum ISegmentStatus {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  STARTED = 'STARTED',
}

export interface IEmptyStateWrapperProps {
  children?: any;
  parent: IEmptyStateParentSources;
  backgroundType: IEmptyStateBGTypes;
  isBroadcasting?: boolean;
}

export enum IEmptyStateParentSources {
  STAGE_ATTENDEE = 'stageAttendee',
  STAGE_PREVIEW = 'stagePreview',
  DISCUSSION_LIST = 'discussionList',
  EVENT_LIST = 'eventList',
  STAGE_ORGANIZER = 'stageOrganizer',
  STAGE_SPEAKER = 'stageSpeaker',

  DISCUSSION_LIST_EMPTY = 'discussionListEmpty',
  SPEAKER_LIST_EMPTY = 'speakerListEmpty',
  EXPO_LIST_EMPTY = 'expoListEmpty',
  SCHEDULE_LIST_EMPTY = 'scheduleListEmpty',
  SPONSOR_LIST_EMPTY = 'sponsorListEmpty',
  BRIEFCASE_EMPTY = 'briefcaseEmpty'
}
