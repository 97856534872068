import ImportedInputField from './InputField';
import ImportedSelectField from './SelectField';
import ImportedColorPickerField from './ColorPickerField';
import ImportedDatePickerField from './DatePickerField';
import ImportedImageUploadField from './ImageUploadField';
import ImportedTimePickerField from './TimePickerField';
import ImportedTextAreaField from './TextAreaField';
import ImportedCheckBoxField from './CheckBoxField'
import ImportedVideoUploadField from './VideoUploadField';
import ImportedFileUploadField from './FileUploadField'
import ImportedFieldLabel from './FieldLabel';
import ImportedDateTimePickerField from './DateTimePickerField';
import ImportedToggleField from './ToggleField';

export const InputField = ImportedInputField;
export const SelectField = ImportedSelectField;
export const ColorPickerField = ImportedColorPickerField;
export const DatePickerField = ImportedDatePickerField;
export const ImageUploadField = ImportedImageUploadField;
export const TimePickerField = ImportedTimePickerField;
export const TextAreaField = ImportedTextAreaField;
export const CheckBoxField = ImportedCheckBoxField;
export const VideoUploadField = ImportedVideoUploadField;
export const FileUploadField = ImportedFileUploadField;
export const DateTimePickerField = ImportedDateTimePickerField;
export const ToggleField = ImportedToggleField;
