import React, { useMemo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import produce from 'immer';
// components
import Heading, {
  IHeadingSizes,
  IHeadingColors,
} from '@/components/ui/content/Heading';
// selector
import api from '@/api';
import { makeSelectUserFirstName } from '@/models/account';
import {
  makeSelectEventTimeToStartById,
  makeSelectEventById,
  makeSelectSegmentListByEventId,
  makeSelectStageLayoutByStageId,
  makeSelectStudioIdByStageId,
} from '@/models/event';
// styles + types
import styles from './styles.module.scss';
import { IEmptyStateWrapperProps, IEmptyStateParentSources } from './types';
// hooks
import { useMemoizedSelector } from '@/hooks/use-memoized-selector';
import { getTextByParentSource } from './config';
import MockSpeakerList from '../../MockSpeakerList';
import { useIsPreviewView } from '@/hooks/use-is-preview-view';
import classnames from 'classnames';

const EmptyStateWrapper = (props: IEmptyStateWrapperProps) => {
  const { eventId, stageId } = useParams<{
    eventId: string;
    stageId: string;
  }>();
  const { children, parent, isBroadcasting = false } = props;
  const dispatch = useDispatch();
  const event = useMemoizedSelector(makeSelectEventById, eventId);
  const [pageSections, setPageSections] = useState({
    Schedule: true,
  });
  const stageDetailsByStageId = useMemoizedSelector(
    makeSelectStageLayoutByStageId,
    stageId,
  );
  const stageStudioId = useMemoizedSelector(
    makeSelectStudioIdByStageId,
    stageId,
  );
  const isStudioStage = !!stageStudioId;
  useEffect(() => {
    if (parent === IEmptyStateParentSources.STAGE_ATTENDEE) {
      dispatch({
        type: 'event/getEventSegmentList',
        payload: {
          eventId,
        },
      });
      api.event
        .getAllActiveZone(eventId)
        .then(({ data }) => {
          const scheduleZone = Array.isArray(data)
            ? data.find(each => each.type === 'SCHEDULE') || {}
            : {};
          setPageSections(details =>
            produce(details, draft => {
              draft.Schedule = Boolean(scheduleZone.active);
            }),
          );
        })
        .catch(err => console.error(err));
    }
  }, [eventId, parent]);
  const selectSegmentListByEventId = useMemo(
    () => makeSelectSegmentListByEventId(eventId),
    [],
  );
  const segments = useSelector(selectSegmentListByEventId);
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  Array.isArray(segments) &&
    segments.sort((a, b) => a.startDateTime - b.startDateTime);

  const filteredSegmentsWithDateFormat = Array.isArray(segments)
    ? segments.filter(item => !item.hiddenSegment && item.stageId === stageId)
    : [];

  const currentUserFirstName = useMemoizedSelector(makeSelectUserFirstName);
  const startTimeDiff = useMemoizedSelector(
    makeSelectEventTimeToStartById,
    eventId,
  );
  const { heading, subtext } = getTextByParentSource(
    event,
    parent,
    {
      firstName: currentUserFirstName,
      eventCountdown: startTimeDiff,
    },
    isBroadcasting,
    filteredSegmentsWithDateFormat,
    segments,
    pageSections,
    new Date(),
  );
  const { isPreview, shouldMockSpeakers } = useIsPreviewView();
  if (isPreview && shouldMockSpeakers && !isStudioStage) {
    return (
      <div
        className={styles.mockStageBg}
        style={{
          background: `url(${stageDetailsByStageId?.bgUrl})`,
        }}
      >
        <MockSpeakerList />
      </div>
    );
  }
  const HeadingElement = (
    <div className={styles.headingElement}>
      {heading.map(h => {
        const { text, style } = h;
        return (
          <Heading
            text={text}
            headingStyle={style}
            headingSize={IHeadingSizes.LARGE}
            headingColor={IHeadingColors.NEUTRAL_DARKEST_3}
          />
        );
      })}
    </div>
  );

  const SubtextElement = (
    <div className={styles.subtextElement}>
      {subtext.map(s => {
        const { text, style } = s;
        return (
          <Heading
            text={text}
            headingStyle={style}
            headingSize={IHeadingSizes.XXSMALL}
            headingColor={IHeadingColors.NEUTRAL_DARKEST_3}
          />
        );
      })}
    </div>
  );

  const ChildElement = (() => {
    if (children) {
      return <div className={styles.childrenElement}>{children}</div>;
    }
    return null;
  })();

  return (
    <div className={classnames(styles.emptyStateWrapper, {
      [styles.backdropBlur]: parent !== IEmptyStateParentSources.STAGE_ATTENDEE
      })}>
      {HeadingElement}
      {SubtextElement}
      {ChildElement}
    </div>
  );
};

export default EmptyStateWrapper;
export { IEmptyStateParentSources };
