import React, { useState, useEffect, useMemo, forwardRef } from 'react';
import classnames from 'classnames';
// components
import FieldLabel from '../FieldLabel';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import AsyncCreatableSelect from 'react-select/async-creatable';
// styles + types
import styles from './styles.module.scss';
import FormValidationErrorTooltip from '../../FormValidationErrorTooltip';
import TooltipDiv from '@/components/ui/icons/TooltipDiv';
import { getCssVar } from '@/utils/cssVars';
import { IContentColors } from '@/types';

const SelectField = forwardRef((props, ref) => {
  const {
    labelStyles,
    containerStyles = '',
    customContainerStyles = {},
    className = '',
    options = [],
    error,
    label,
    onChange,
    readOnly,
    value,
    isMandatory,
    tooltip,
    onInputChange = () => {},
    menuHeight = 200,
    customStyles = {},
    selectProps = {},
    showLabelContainer = true,
    tooltipPlace = 'top',
    shouldApplyComponentStyles = true,
    shouldApplyContainerStyles = true,
    placeholder = 'Select',
    triggerer,
    selectFieldStyles = '',
    arrowMarginRight = 19,
    arrowColor = '#222222',
    dataTestId = '',
    componentsContainer= {},
    countryCodeField = false,
  } = props as any;

  const [selectedOption, setSelectedOption] = useState(null);
  useEffect(() => {
    if (value) {
      let optionValue = value;
      if (typeof value === 'object') {
        optionValue = value.value;
      }

      for (const option of options) {
        if (option.value === optionValue) {
          setSelectedOption(option);
          break;
        }
      }
    } else {
      setSelectedOption(null);
    }
  }, [options, value]);

  const SelectComp = useMemo(() => {
    if (selectProps.creatable === true) {
      return CreatableSelect;
    }

    if (selectProps.asyncCreatable === true) {
      return AsyncCreatableSelect;
    }

    return Select;
  }, [selectProps.creatable, selectProps.asyncCreatable]);

  const maxMenuHeightProps = (() => {
    if (triggerer === 'CreateOrUpdatePollModal') {
      return {
        maxMenuHeight: 72,
        menuPlacement: 'auto',
      };
    }

    return {};
  })();

  return (
    <div
      className={classnames(
        { [styles.container]: shouldApplyContainerStyles },
        {
          [styles[containerStyles]]: !!containerStyles,
        },
        { [styles.disableContainer]: readOnly },
      )}
      style={customContainerStyles}
      data-testid={dataTestId}
    >
      {showLabelContainer && (
        <div className={styles.titleLabelContainer}>
          {label && label.length && (
            <FieldLabel value={label} className={labelStyles} />
          )}
          {isMandatory && <FieldLabel value="*" className={labelStyles} />}
          {label && label.length && tooltip && (
            <TooltipDiv
              dataFor={`tootltip_select_field_${label}`}
              tooltipText={tooltip}
              tooltipPlace={tooltipPlace}
            />
          )}
        </div>
      )}
      <SelectComp
        {...maxMenuHeightProps}
        className={classnames(
          { [styles.selectField]: shouldApplyComponentStyles },
          { [styles.countryCodeField]: !!countryCodeField },
          {
            [selectFieldStyles]: !!selectFieldStyles,
            [styles.inputFieldError]: error,
          },
        )}
        styles={{
          menu: provided => ({ ...provided, zIndex: 9999 }),
          ...customStyles,
          menuList: provided => ({
            ...provided,
            height: menuHeight,
            color: getCssVar(IContentColors.MONOCHROME_CONTRAST)
          }),
          singleValue: provided => ({
            ...provided,
            color: getCssVar(IContentColors.MONOCHROME_CONTRAST),
          }),
          option: (provided, state) => ({
            ...provided,
            '&:hover': {
              backgroundColor: `${getCssVar(IContentColors.NEUTRAL_BASE_3)}`,
            },
            color: state.isFocused ? getCssVar(IContentColors.MONOCHROME_CONTRAST) :
            getCssVar(IContentColors.NEUTRAL_CONTRAST_1),
            backgroundColor: state.isFocused ?`${getCssVar(IContentColors.NEUTRAL_BASE_3)}`:
            getCssVar(IContentColors.NEUTRAL_BASE_2)
          }),
          placeholder: (provided, state) => ({
            ...provided,
            maxWidth: '85%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }),
        }}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: () =>
            selectProps.hideDropdownIndicator === true ? null : (
              <div style={{ marginRight: `${arrowMarginRight}px` }}>
                <DownArrow arrowColor={arrowColor} />
              </div>
            ),
          ...componentsContainer,
        }}
        isSearchable={true}
        placeholder={placeholder}
        options={options}
        value={[selectedOption]}
        onChange={onChange}
        onInputChange={onInputChange}
        {...selectProps}
        isDisabled={readOnly}
        ref={ref}
      />
      {error && <FormValidationErrorTooltip value={error} dataTestId={`${dataTestId}-error`} />}
    </div>
  );
});

const DownArrow = ({ arrowColor }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.00054 9.879L12.713 6.1665L13.7735 7.227L9.00054 12L4.22754 7.227L5.28804 6.1665L9.00054 9.879Z"
        fill={arrowColor}
      />
    </svg>
  );
};

export default SelectField;
