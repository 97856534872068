import React, { useEffect, useRef, useState } from 'react';
import { useAtom } from 'jotai';
// components
import FieldLabel from '../FieldLabel';
// styles + types
import styles from './styles.module.scss';
import classnames from 'classnames';
import FormValidationErrorTooltip from '../../FormValidationErrorTooltip';
import TooltipDiv from '@/components/ui/icons/TooltipDiv';
import { unfilledRegistrationFieldRefAtom } from './atoms';
import { removeAsteriskFromLabel } from '@/utils/helpers';

const InputField = props => {
  const {
    containerStyles,
    labelStyles,
    fieldStyles,
    error,
    label,
    onChange,
    onBlur,
    placeholder,
    readOnly,
    value,
    submitFailed,
    isLastElement,
    isMandatory,
    maxLength,
    tooltip,
    name,
    type = 'text',
    submitError,
    dirtySinceLastSubmit,
    touched,
    isSponsorLandingPage = false,
    required,
    isUpperCaseInput = false,
  } = props;

  const containerWithValidationMessage = classnames(styles.inputField, {
    [styles.addMarginRight]: !isLastElement,
  });

  const [showMaxLengthExceededError, setShowMaxLengthExceededError] = useState(
    false,
  );
  const [active, setActive] = useState(false);
  const [passwordHintMode, setPasswordHintMode] = useState(false);
  const [registrationFieldRef, setRegistrationFieldRef] = useAtom(
    unfilledRegistrationFieldRefAtom,
  );
  const fieldRef = useRef<HTMLInputElement>(null);

  const validationError = (() => {
    if (!registrationFieldRef && !fieldRef?.current?.value && error) {
      setRegistrationFieldRef(fieldRef);
    }

    if (error && submitFailed === undefined) {
      // with out Form Section
      return true;
    } else if (
      (error || (submitError && !dirtySinceLastSubmit && touched)) &&
      submitFailed === true
    ) {
      // with Form Section
      return true;
    } else {
      if (registrationFieldRef && !!registrationFieldRef?.current?.value) {
        setRegistrationFieldRef(null);
      }
      return false;
    }
  })();

  useEffect(() => {
    if (!fieldRef.current) return;
    // To reflect data changes into the field text value.
    if (value === undefined) {
      fieldRef.current.value = '';
      return;
    }
    if (fieldRef.current.value !== value) {
      fieldRef.current.value = value;
    }
    if (value.length > 0) {
      setActive(true);
    }
  }, [value]);

  const handleChangeInput = event => {
    const { value } = event.target;
    if (value.length == 0) {
      setActive(false);
    } else {
      setActive(true);
    }
    setShowMaxLengthExceededError(
      maxLength !== undefined && maxLength === value.length,
    );
    onChange(event);
  };

  const onKeyUp = event => {
    if (!isUpperCaseInput) {
      return;
    }
    const start = event.target.selectionStart;
    const end = event.target.selectionEnd;
    event.target.value = event.target.value.toUpperCase();
    event.target.setSelectionRange(start, end);
  };

  return (
    <div
      className={classnames(styles.containerWrapper, {
        [styles.active]: active,
        [styles.containsError]: validationError,
      })}
    >
      <div
        className={classnames(styles.container, {
          [styles.sponsorLandingPageInputContainer]: isSponsorLandingPage,
        })}
      >
        <div
          className={classnames({
            [styles.titleLabelContainer]: !isSponsorLandingPage,
          })}
        >
          {(label && label.length) || (placeholder && placeholder.length) ? (
            <FieldLabel
              value={
                label
                  ? removeAsteriskFromLabel(label)
                  : removeAsteriskFromLabel(placeholder)
              }
              className={classnames(labelStyles, {
                ['sponsorLandingPageLabel']: isSponsorLandingPage,
              })}
              isMandatory={required}
              for={name}
            />
          ) : null}
          {label && label.length && tooltip ? (
            <TooltipDiv
              dataFor={`tooltip_input_${label}`}
              tooltipText={tooltip}
            />
          ) : null}
        </div>
        <input
          className={classnames(styles.inputField, {
            [styles.sponsorLandingPageInputField]: isSponsorLandingPage,
          })}
          defaultValue={value || ''}
          disabled={readOnly}
          onChange={handleChangeInput}
          onBlur={onBlur}
          placeholder={
            isSponsorLandingPage
              ? removeAsteriskFromLabel(placeholder)
              : placeholder
          }
          tabIndex={0}
          maxLength={maxLength}
          type={type}
          ref={fieldRef}
          name={name}
          onKeyUp={onKeyUp}
        />
        {validationError && !isSponsorLandingPage && (
          <div className={styles.errorIcon}>
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.99999 5.58599L11.95 0.635986L13.364 2.04999L8.41399 6.99999L13.364 11.95L11.95 13.364L6.99999 8.41399L2.04999 13.364L0.635986 11.95L5.58599 6.99999L0.635986 2.04999L2.04999 0.635986L6.99999 5.58599Z"
                fill="white"
              />
            </svg>
          </div>
        )}
      </div>
      {validationError && <FormValidationErrorTooltip value={error} />}
      {!error && submitError && validationError && (
        <div className={styles.submitErrorText}>
          <FormValidationErrorTooltip value={submitError} />{' '}
        </div>
      )}
    </div>
  );
};

export default React.memo(InputField);
