import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// components
import FieldLabel from '../FieldLabel';
import styles from './styles.module.scss';
import { format, parse, setMonth, setDate, setYear, setHours, setMinutes } from 'date-fns';
import FormValidationErrorTooltip from '../../FormValidationErrorTooltip';
import { setSeconds } from 'date-fns/esm';
import TooltipDiv from '@/components/ui/icons/TooltipDiv';

const DATE_TIME_STRING_FORMAT = "yyyy-MM-dd'T'HH:mm:ssXXX";
const DATE_DISPLAY_FORMAT = "EEEE, dd MMMM yyyy";

const DatePickerField = (props) => {
  const {
    className = '',
    error,
    label,
    onChange,
    placeholder,
    readOnly,
    value,
    isMandatory,
    tooltip,
  } = props;

  const [dateObject, setDateObject] = useState(new Date());
  const [dateString, setDateString] = useState(null);

  useEffect(() => {
    if (!value) {
      setDateObject(new Date())
      return;
    }

    const parsedDate = parse(value, DATE_TIME_STRING_FORMAT, Date.now());
    if (isNaN(parsedDate) === false) {
      setDateObject(parsedDate);
      const newDateString = format(parsedDate, DATE_TIME_STRING_FORMAT);
      if (dateString !== newDateString) {
        setDateString(newDateString);
      }
    }

  }, [value])

  useEffect(() => {
    if (!dateString) return;

    if (onChange && dateString !== value) {
      // onChange(format(date, "yyyy-MM-dd'T'kk:mm:ssXXX"));
      onChange(dateString);
    }
  }, [dateString])

  const updateDate = (newDateObject) => {
    const dateObjectCopy = new Date(dateObject.getTime());
    let newDateTime = setDate(dateObjectCopy, newDateObject.getDate());
    newDateTime = setMonth(newDateTime, newDateObject.getMonth());
    newDateTime = setYear(newDateTime, newDateObject.getYear());

    setDateObject(newDateObject)
    const newDateString = format(newDateObject, DATE_TIME_STRING_FORMAT);
    setDateString(newDateString);
  }

  const CustomDatePickerInput = ({ value, onClick }) => (
    <div className={styles.datePickerCustomDisplay} onClick={onClick}>
      <div className={styles.dateText}>{value}</div>
      <DownArrow />
    </div>
  );

  return (
    <div className={styles.container}>
      <div className={styles.titleLabelContainer}>
        {
          label && label.length ? (
            <FieldLabel value={label} />
          ) : null
        }
        {
          isMandatory &&
          <FieldLabel value={`*`} />
        }
        {
          label && label.length && tooltip ? (
            <TooltipDiv dataFor={`tooltip_datepicker_${label}`} tooltipText={tooltip}/>
          ) : null
        }
      </div>
      <DatePicker
        dateFormat={DATE_DISPLAY_FORMAT}
        selected={dateObject}
        onChange={(date) => { updateDate(date) }}
        formatWeekDay={nameOfDay => nameOfDay.substr(0, 3)}
        wrapperClassName={styles.datePickerWrapper}
        customInput={<CustomDatePickerInput />}
        popperPlacement='right'
        timeFormat="HH:mm a"
        showTimeInput={true}
        // showTimeSelect={true}
        // timeClassName={() => {}}
        calendarClassName={styles.calendarWrapper}
        customTimeInput={ExampleCustomTimeInput}
        timeCaption={"My Time"}
        timeInputLabel={"My Time Finally"}
      />
      { error && <FormValidationErrorTooltip value={error} />}
    </div>
  );
};


const ExampleCustomTimeInput = ({ date, value, onChange }) => {
  return (
    <div className={styles.customTime}>
      <input
        value={value}
        onChange={e => onChange(e.target.value)}
        style={{ border: "solid 1px pink" }}
      />
    </div>
  )
}


const DownArrow = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.00054 9.879L12.713 6.1665L13.7735 7.227L9.00054 12L4.22754 7.227L5.28804 6.1665L9.00054 9.879Z" fill="#222222" />
    </svg>
  )
}

// export default React.memo(ColorPickerField);
export default DatePickerField;
