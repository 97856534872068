import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
// components
import FieldLabel from '../FieldLabel';
// styles + types
import styles from './styles.module.scss';
import FormValidationErrorTooltip from '../../FormValidationErrorTooltip';
import TooltipDiv from '@/components/ui/icons/TooltipDiv';

const TextAreaField = (props) => {
  const {
    containerStyles = '',
    customContainerStyles={},
    className = '',
    labelStyles,
    fieldStyles={},
    error,
    label,
    onChange,
    onBlur,
    placeholder,
    readOnly,
    value,
    isMandatory,
    maxLength,
    tooltip,
    customStyles,
    rows=6,
    dataTestId,
  } = props;

  const fieldRef = useRef();
  const [showMaxLengthExceededError, setShowMaxLengthExceededError] = useState(false);
  useEffect(() => {
    if (!fieldRef.current) return;
    // To reflect data changes into the field text value.
    if (value === undefined){
      fieldRef.current.value = '';
      return;
    } 
    if (fieldRef.current.value !== value) {
      fieldRef.current.value = value;
    }
  }, [value])

  const handleChangeInput = (event) => {
    const { value } = event.target;
    setShowMaxLengthExceededError(maxLength !== undefined && maxLength === value.length);
    onChange(event);
  }

  return (
    <div className={
      classnames(styles.container, {
        [styles[containerStyles]]: !!containerStyles
      })
    }
      style={customContainerStyles}>
      <div className={styles.titleLabelContainer}>
        {
          label && label.length ? (
            <FieldLabel value={label} className={labelStyles} />
          ) : null
        }
        {
          isMandatory &&
          <FieldLabel value={`*`} className={labelStyles} />
        }
        {
          maxLength &&
          <FieldLabel value={`(Max ${maxLength} characters)`} className={labelStyles && labelStyles === 'whiteLabel' ? "maxLengthWhiteLable" : "maxLengthLable"} />
        }
        {
          label && label.length && tooltip ? (
            <TooltipDiv dataFor={`tooltip_text_area_${label}`} tooltipText={tooltip}/>
          ) : null
        }
      </div>
      <textarea
        className={styles.textAreaField}
        rows={rows}
        value={value || ''}
        onChange={handleChangeInput}
        ref={fieldRef}
        maxLength={maxLength}
        placeholder={placeholder ? placeholder : "Enter text here..."}
        disabled={readOnly}
        style={customStyles}
        data-testid={dataTestId}
      />
      {
        (maxLength && !!value && value.length > Number(maxLength * 0.8)) && (
          <div className={labelStyles && labelStyles === 'whiteLabel' ? styles.textLimitWhite : styles.textLimit}>{value.length}/{maxLength} characters</div>
        )
      }
      { error && <FormValidationErrorTooltip value={error} />}
      {/* {showMaxLengthExceededError && <FieldLabel value={'Max Character Limit Reached!'} className={"maxLimitExceededText"} />} */}
    </div>
  );
};

export default React.memo(TextAreaField);
