import React from 'react';
import classnames from 'classnames';
import Linkify from 'react-linkify';
// types
import { INewLabelProps } from './types';
import {
  IContentTextSizeTypes,
  IContentTextStyleTypes,
  IContentTextColorTypes,
  IContentTextMarginTypes,
  IContentBGColorTypes,
} from '@/types';
// styles
import styles from './styles.module.scss';

const NewLabel = (props: INewLabelProps) => {
  const {
    labelBg = IContentBGColorTypes.DEFAULT,
    labelColor = IContentTextColorTypes.WHITE,
    labelSize = IContentTextSizeTypes.DEFAULT,
    labelStyle = IContentTextStyleTypes.DEFAULT,
    labelMargin = IContentTextMarginTypes.NO_MARGIN,
    labelFor,
    text,
    onClick,
    linkify = false,
    link,
    readOnly = false,
    clickable = false,
    hasHover = false,
    dataFor,
    style,
  } = props;

  const pointerText =
    !!onClick || !!labelFor || !!clickable
      ? styles.clickHover
      : styles.normalText;

  const componentDecorator = (href, text, key) => (
    <a
      className={classnames(
        styles.link,
        {
          [styles.clickHover]: !!onClick || !!labelFor,
          [styles.borderClass]: hasHover,
        },
        styles[labelBg],
        styles[labelColor],
        styles[labelMargin],
        styles[labelSize],
        styles[labelStyle],
        readOnly ? styles.disabledText : pointerText,
      )}
      href={href}
      key={key}
      target="_blank"
    >
      {text}
    </a>
  );

  return (
    <label
      htmlFor={labelFor}
      onClick={onClick}
      className={classnames(
        {
          [styles.clickHover]: !!onClick || !!labelFor,
        },
        styles[labelBg],
        styles[labelColor],
        styles[labelMargin],
        styles[labelSize],
        styles[labelStyle],
        readOnly ? styles.disabledText : pointerText,
      )}
      data-for={dataFor}
      data-tip={!!dataFor}
      style={style}
    >
      {link && (
        <a
          className={classnames(
            {
              [styles.clickHover]: !!onClick || !!labelFor,
            },
            styles[labelBg],
            styles[labelColor],
            styles[labelMargin],
            styles[labelSize],
            styles[labelStyle],
            readOnly ? styles.disabledText : pointerText,
          )}
          href={link}
          target="_blank"
        >
          {text}
        </a>
      )}
      {!link &&
        (linkify ? (
          <Linkify componentDecorator={componentDecorator}>{text}</Linkify>
        ) : (
          text
        ))}
    </label>
  );
};

export {
  IContentTextColorTypes as INewLabelColors,
  IContentTextMarginTypes as INewLabelMargins,
  IContentTextSizeTypes as INewLabelSizes,
  IContentTextStyleTypes as INewLabelStyles,
};
export default NewLabel;
