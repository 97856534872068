export const MINUTE_OPTIONS = [
  "00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10",
  "11", "12", "13", "14", "15", "16", "17", "18", "19", "20",
  "21", "22", "23", "24", "25", "26", "27", "28", "29", "30",
  "31", "32", "33", "34", "35", "36", "37", "38", "39", "40",
  "41", "42", "43", "44", "45", "46", "47", "48", "49", "50",
  "51", "52", "53", "54", "55", "56", "57", "58", "59",
];

export const HOUR_OPTIONS = [
  "12", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"
];

export const MERIDIAN_OPTIONS = {
  AM: "AM",
  PM: "PM",
};

export class CicularArray  {
  constructor(array) {
    this.arr = array;
    this.arrLength = array.length;
  }

  getItem(index, offset = 0) {
    let finalIndex = index + offset;
    if (finalIndex < 0) {
      finalIndex = this.arrLength + finalIndex;
    } else if (finalIndex > this.arrLength - 1) {
      finalIndex = finalIndex - this.arrLength;
    }
    return this.arr[finalIndex];
  }

  getIndex(number) {
    for (const [index, opt] of Object.entries(this.arr)) {
      if (Number(opt) === number) return Number(index);
    }
    return 0;
  }

  onScroll(currentIndex, deltaY) {
    console.log(deltaY);
    let newIndex = currentIndex;
    
    if (deltaY > 0) {
      if (deltaY > 120) {
        newIndex = (currentIndex + 3) % this.arrLength;
      } else if (deltaY > 60) {
        newIndex = (currentIndex + 2) % this.arrLength;
      } else {
        newIndex = (currentIndex + 1) % this.arrLength;
      }
    } else {
      if (deltaY < -120) {
        newIndex = currentIndex === 0 ? this.arrLength - 3 : currentIndex - 3;
      } else if (deltaY < -60) {
        newIndex = currentIndex === 0 ? this.arrLength - 2 : currentIndex - 2;
      } else {
        newIndex = currentIndex === 0 ? this.arrLength - 1 : currentIndex - 1;
      }
    }

    return newIndex;
  }
}

