import React, { useMemo, useEffect } from 'react';
import NewLabel, { INewLabelColors, INewLabelSizes, INewLabelStyles } from '@/components/ui/content/NewLabel';
//styles
import styles from './styles.module.scss';
// types
import { ITickerProps } from './types';

const TickerComponent = (props: ITickerProps) => {
  const { list = [] } = props;
  const displayText = list[0] && list[0].bio !== undefined ? list[0].bio : 'Your text comes here | Your text comes here';
  const backgroundColour = list[3] ? list[3].backgroundColour : '#FFFFFF';
  const textColour = list[4] ? list[4].textColour : '#000000';
  // check if showCustomSpeed toggle is checked
  const showCustomSpeed = list[2] ? list[2].showCustomSpeed : false;
  let animationSpeed = list[1] ? list[1].tickerSpeed : 6;
  const contentLength = useMemo(() => {
    return (displayText && displayText.length) || 0;
  }, [list]);
  // falling back to old code when showCustomSpeed toggle is unchecked
  if (!showCustomSpeed) {
    animationSpeed = contentLength < 30 ? 6 : (contentLength / 20 * 4);
  }
  return (
    list.length > 0 && (
      <div
        className={styles.tickerContainer}
        style={{ backgroundColor: `${backgroundColour}` }}
      >
        {/* added a wrapper div around ticker text to fix bug in animation */}
        <div
          style={{
            animation: `${styles.tickerTextWrapper} ${animationSpeed}s linear infinite`,
            width: '100%',
          }}
        >
          <div
            style={{
              animation: `${styles.tickerContainer} ${animationSpeed}s linear infinite`,
            }}
          >
            <label
              style={{ color: `${textColour}` }}
              className={styles.labelStyle}
            >
              {displayText}
            </label>
          </div>
        </div>
      </div>
    )
  );
};

export default TickerComponent;
