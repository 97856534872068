import React, { useState, useEffect } from 'react';
import Pickr from '@simonwep/pickr';
import '@simonwep/pickr/dist/themes/nano.min.css';
// components
import FieldLabel from '../FieldLabel';
import styles from './styles.module.scss';
import classnames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import TooltipDiv from '@/components/ui/icons/TooltipDiv';

const ColorPickerField = (props) => {
  const {
    className = '',
    error,
    label,
    onChange,
    placeholder,
    readOnly,
    value,
    isLastElement,
    isMandatory,
    tooltip,
    // added labelStyles property
    labelStyles,
    containerStyles = '',
    colorPickerStyles = '',
  } = props;

  const [pickerID] = useState(`CP-${uuidv4()}`)
  const [colorUpdated, setColorUpdated] = useState(false);
  const [hexCode, setHexCode] = useState(null);
  const [pickerInstance, setPickerInstance] = useState(null);
  const defaultColor = '#484848';

  useEffect(() => {
    const inputElement = document.querySelector(`#${pickerID}`);

    const pickr = Pickr.create({
      el: inputElement,
      theme: 'nano', // or 'monolith', or 'nano'
      position: 'bottom-start',
      components: {
        // Main components
        preview: true,
        opacity: true,
        hue: true,
        // Input / output Options
        interaction: {
          hex: false,
          rgba: false,
          hsla: false,
          hsva: false,
          cmyk: false,
          input: true,
          clear: true,
          // save: true
        }
      }
    }).on('init', (pickr) => {
      setPickerInstance(pickr);
    }).on('change', (color) => {
      if (!color) return
      const colorString = color.toHEXA().toString(0);
      setHexCode(colorString);
      setColorUpdated(true);
      // pickr.hide();
    }).on('save', (color) => {
      if (!color) {
        setHexCode(null);
        return;
      }
      const colorString = color.toHEXA().toString(0);
      setHexCode(colorString);
      setColorUpdated(true);
      pickr.hide();
    });
  }, [])

  useEffect(() => {
    if (!pickerInstance) return;

    const colorValue = value || defaultColor;

    pickerInstance.setColor(colorValue, true);
    pickerInstance.applyColor(true);
    const colorString = pickerInstance.getSelectedColor().toHEXA().toString(0);
    setHexCode(colorString);
  }, [value, pickerInstance])

  useEffect(() => {
    if (!hexCode) return;

    if (colorUpdated) {
      onChange && onChange(hexCode);
      setColorUpdated(false);
    }
  }, [hexCode, colorUpdated])

  const showPicker = () => {
    if (readOnly === true) {
      return
    }
    if (pickerInstance) {
      pickerInstance.show();
    }
  }

  return (
    <div className={classnames(styles.container,[styles.containerStyles])}>
      <div className={styles.titleLabelContainer}>
        {
          label && label.length ? (
            <FieldLabel value={label} className={labelStyles}/>
          ) : null
        }
        {
          isMandatory &&
          <FieldLabel value={`*`} className={labelStyles}/>
        }
        {
          tooltip &&
          <TooltipDiv dataFor={`tooltip_color_picker_${pickerID}`} tooltipText={tooltip}/>
        }
      </div>
      <div className={classnames(styles.colorPicker,[styles.colorPickerStyles])} onClick={showPicker}>
        <input disabled={readOnly} type="text" className="pickr" id={pickerID} />
        <div className={styles.hexCode}>{hexCode}</div>
        <DownArrow />
      </div>
      {/* {error && <FormValidationErrorTooltip value={error} />} */}
    </div>
  );
};

const DownArrow = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.00054 9.879L12.713 6.1665L13.7735 7.227L9.00054 12L4.22754 7.227L5.28804 6.1665L9.00054 9.879Z" fill="#222222" />
    </svg>
  )
}

// export default React.memo(ColorPickerField);
export default ColorPickerField;
