import React from 'react';

const IFrame = ({ url, width = '100%', height = '100%' }) => {
  return (
    <iframe
      style={{
        width: '100%',
        height: '100%'
      }}
      src={url} 
      allow="camera *;microphone *;autoplay">
    </iframe>
  );
};

export default IFrame;
