import { IContentBGColorTypes, IUIIcons } from '@/types';

export enum TooltipTypes {
  left = 'left',
  right = 'right',
  top = 'top',
  bottom = 'bottom',
  none = 'none',
}

export interface IIconButtonProps {
  activeIcon?: IUIIcons;
  defaultIcon?: IUIIcons;
  disabled?: boolean;
  iconBG?: IContentBGColorTypes;
  onClick?: (e?: React.MouseEvent<HTMLElement>) => void;
  showDefault?: boolean;
  type?: IIconButtonTypes;
  className?: string;
  tooltip?: string;
  iconSize?: number;
  iconStyle?: any;
  style?: any;
  id?: any;
  overlayIcon?: IUIIcons;
  overlayIconClassName?: string;
  triggerTooltip?: boolean;
  hideAfterSeconds?: number;
  tooltipText?: string;
  dataTestId?: string;
}

export enum IIconButtonTypes {
  ONLY_ICON_GREY_BLACK = 'onlyIconGreyBlack',
  ROUND_GREY_BLACK = 'roundGreyBlack',
  ROUND_WHITE_GREY = 'roundWhiteGrey',
  ROUND_PRIMARY = 'roundPrimary',
  ROUND_WHITE_CONTRAST = 'roundWhiteContrast',
  ROUND_NEUTRAL_BASE_1 = 'roundNeutralBase1',
  ROUND_NEUTRAL_BASE_3 = 'roundNeutralBase3',
  ROUND_MONOCHROME_CONTRAST = 'monochromeContrast',
  SMALL_CUSTOM_BG = 'smallCustomBG',
  ROUND_SUCCESS_DANGER = 'roundSuccessDanger',
  ROUND_DARK_TRANSPARENT_WHITE = 'roundDarkTransparentWhite',
  ROUND_DARK_TRANSPARENT_DARK = 'roundDarkTransparentDark',
  ROUND_DARK_GREY_WHITE = 'roundDarkGreyWhite',
  ROUND_WHITE_DARK_GREY = 'roundWhiteDarkGrey',
  ROUND_THEME = 'roundTheme',
  ROUND_CONTRAST = 'roundContrast',
  ROUND_MID_3 = 'roundMid3',
  ONLY_ICON_CONTRAST_4 = 'onlyIconContrast4',
  SQUARE_NEUTRAL_BASE_3 = 'squareNeutralBase3',
  SQUARE_NEUTRAL_BASE_2 = 'squareNeutralBase2',
  SQUARE_BLACK_CONTRAST = 'squareBlackContrast',
  ROUND_BLACK_CONTRAST = 'roundBlackContrast',
  ROUND_DISABLED = 'roundDisabled',
}
