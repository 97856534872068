import React, { useState, useEffect, useRef, useCallback } from 'react';
import classnames from 'classnames';
import moment from 'moment';
import { parse, set, subDays } from 'date-fns';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import soft from 'timezone-soft';
import { utcToZonedTime, format as tzFormat } from 'date-fns-tz';
// components
import FieldLabel from '../FieldLabel';
import styles from './styles.module.scss';
import FormValidationErrorTooltip from '../../FormValidationErrorTooltip';
import TooltipDiv from '@/components/ui/icons/TooltipDiv';
import Modal from '@/components/ui/modals/Modal';
import { IModalTypes } from '@/components/ui/modals/Modal/types';
import { removeAsteriskFromLabel } from '@/utils/helpers';
import {
  CicularArray,
  HOUR_OPTIONS,
  MINUTE_OPTIONS,
  MERIDIAN_OPTIONS,
} from './config';
import { convertInAnotherTimeZone } from '@/utils/helpers';

const DATE_TIME_STRING_FORMAT = "yyyy-MM-dd'T'HH:mm:ssXXX";
const DATE_TIME_DISPLAY_FORMAT = 'EEEE, dd MMMM yyyy - hh:mm a';
const DATE_DISPLAY_FORMAT = 'd MMM yyyy';
const TIME_DISPLAY_FORMAT = 'hh:mm a';

const HOURS = new CicularArray(HOUR_OPTIONS);
const MINUTES = new CicularArray(MINUTE_OPTIONS);

moment.locale('en', {
  months: 'January_February_March_April_May_June_July_August_September_October_November_December'.split(
    '_',
  ),
  monthsShort: 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_'),
  weekdays: 'Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday'.split(
    '_',
  ),
  weekdaysShort: 'Sun_Mon_Tue_Wed_Thu_Fri_Sat'.split('_'),
  weekdaysMin: 'S_M_T_W_T_F_S'.split('_'),
});

const DateTimePickerField = props => {
  const {
    className = '',
    touched,
    error,
    label,
    onChange,
    placeholder,
    readOnly,
    value,
    isMandatory,
    tooltip,
    onDoneCallback = () => {},
    timezone,
    labelStyles,
    isEditProfileSection = false,
    showTimePickerOnly = false,
    showDatePickerOnly = false,
    isSponsorLandingPage = false,
    dataTestId = '',
  } = props;
  const today = new Date();
  const yesterday = subDays(new Date(), 1);

  // Date variables
  const [dateObject, setDateObject] = useState(today);
  const [dateString, setDateString] = useState(null);
  const [dateTimeDisplay, setDateTimeDisplay] = useState(null);

  // Time Variables
  const [hourIndex, setHourIndex] = useState(showDatePickerOnly ? 0 : 10);
  const [showHourInput, setHourInputDisplay] = useState(false);
  const [minuteIndex, setMinuteIndex] = useState(0);
  const [showMinuteInput, setMinuteInputDisplay] = useState(false);
  const [meridianValue, setMeridianValue] = useState(MERIDIAN_OPTIONS.AM);

  const [ianaCode, setIANACode] = useState(null);

  // Refs
  const closeModal = useRef();
  const hourInput = useRef();
  const minuteInput = useRef();

  useEffect(() => {
    if (showHourInput && hourInput.current) {
      hourInput.current.focus();
    }
    if (showMinuteInput && minuteInput.current) {
      minuteInput.current.focus();
    }
  }, [showHourInput, showMinuteInput]);

  useEffect(() => {
    if (!timezone) return;
    const displayedValues = soft(timezone)[0];
    if (displayedValues?.standard) {
      setIANACode(displayedValues.standard.abbrev);
    }
  }, [timezone]);

  const getZonedDisplayTime = useCallback((date: Date) => {
    const format = showDatePickerOnly
      ? DATE_DISPLAY_FORMAT
      : DATE_TIME_DISPLAY_FORMAT;
    const zonedFormat = showTimePickerOnly ? TIME_DISPLAY_FORMAT : format;

    const zonedDisplayTime = tzFormat(date, zonedFormat, {
      timeZone: timezone,
    });
    return zonedDisplayTime;
  }, []);

  useEffect(() => {
    if (!value) return;

    let parsedDate = parse(value, DATE_TIME_STRING_FORMAT, Date.now());
    const isoDate = new Date(parsedDate.toISOString());

    if (!!timezone && !isNaN(isoDate)) {
      // const zonedDate = toDate(value, timezone)
      parsedDate = utcToZonedTime(isoDate, timezone);
    }

    if (isNaN(parsedDate) === false) {
      const timezoneDate = timezone
        ? convertInAnotherTimeZone(isoDate, timezone)
        : isoDate;
      setDateObject(timezoneDate);
      const hours = Number(parsedDate.getHours());
      const minutes = Number(parsedDate.getMinutes());
      setHourIndex(HOURS.getIndex(hours % 12 || 12));
      setMinuteIndex(MINUTES.getIndex(minutes));
      setMeridianValue(hours >= 12 ? MERIDIAN_OPTIONS.PM : MERIDIAN_OPTIONS.AM);
      parsedDate.setSeconds(0);

      const zonedDiplayTime = getZonedDisplayTime(parsedDate);
      setDateTimeDisplay(zonedDiplayTime);

      const newDateString = tzFormat(parsedDate, DATE_TIME_STRING_FORMAT, {
        timeZone: timezone,
      });
      if (dateString !== newDateString) {
        setDateString(newDateString);
      }
    }
  }, [value, timezone]);

  useEffect(() => {
    if (!dateString) return;

    if (onChange && dateString !== value) {
      // if (!!value && !!dateString) {
      //   const oldTime = new Date(value).getTime();
      //   const newTime = new Date(dateString).getTime();
      //   console.log('raghu set oldTime--',oldTime);
      //   console.log('raghu set newTime--',newTime);
      //   if (oldTime === newTime) return;
      // }
      onChange(dateString);
    }
  }, [dateString]);

  // Form functions
  const onDone = () => {
    if (!dateObject) return;

    const hour = Number(HOUR_OPTIONS[hourIndex]);
    const minute = Number(MINUTE_OPTIONS[minuteIndex]);
    let hoursIn24HoursNotation;
    if (meridianValue === MERIDIAN_OPTIONS.PM) {
      hoursIn24HoursNotation = hour === 12 ? hour : hour + 12;
    } else {
      hoursIn24HoursNotation = hour === 12 ? 0 : hour;
    }

    let finalDateTime = set(dateObject, {
      hours: hoursIn24HoursNotation,
      minutes: minute,
      seconds: 0,
    });

    const zonedDiplayTime = getZonedDisplayTime(finalDateTime);
    setDateTimeDisplay(zonedDiplayTime);

    const newDateString = tzFormat(finalDateTime, DATE_TIME_STRING_FORMAT, {
      timeZone: timezone,
    });
    if (dateString !== newDateString) {
      setDateString(newDateString);
    }

    closeModal && closeModal.current && closeModal.current();
  };

  const updateHourInputValue = () => {
    const hourValue = hourInput.current.value;
    const hour = Number(hourValue);
    if (hour !== NaN && hour > 0 && hour <= 12) {
      setHourIndex(HOURS.getIndex(hour));
    }
  };

  const updateMinuteInputValue = () => {
    const minuteValue = minuteInput.current.value;
    const minute = Number(minuteValue);
    if (minute !== NaN && minute >= 0 && minute < 60) {
      setMinuteIndex(MINUTES.getIndex(minute));
    }
  };

  const saveInputValues = () => {
    if (showHourInput) {
      updateHourInputValue();
      setHourInputDisplay(false);
    }
    if (showMinuteInput) {
      updateMinuteInputValue();
      setMinuteInputDisplay(false);
    }
  };

  // Scroll Handlers
  const handleHourScroll = ({ target, deltaY }) => {
    if (!target) return;

    const newIndex = HOURS.onScroll(hourIndex, deltaY);
    setHourIndex(newIndex);
  };

  const handleMinuteScroll = ({ target, deltaY }) => {
    if (!target) return;

    const newIndex = MINUTES.onScroll(minuteIndex, deltaY);
    setMinuteIndex(newIndex);
  };

  const toggleMeridian = ({ target, deltaY }) => {
    if (!target) return;

    if (deltaY) {
      deltaY > 0
        ? setMeridianValue(MERIDIAN_OPTIONS.AM)
        : setMeridianValue(MERIDIAN_OPTIONS.PM);
    } else {
      meridianValue === MERIDIAN_OPTIONS.AM
        ? setMeridianValue(MERIDIAN_OPTIONS.PM)
        : setMeridianValue(MERIDIAN_OPTIONS.AM);
    }
  };

  // Render Functions
  const renderCustomView = (mode, renderDefault) => {
    // Only for years, months and days view
    if (mode === 'time') {
      return renderDefault();
    }

    const showTimePickerUI = showTimePickerOnly || !showDatePickerOnly;
    const showDivider = !(showTimePickerOnly || showDatePickerOnly);

    const wrapperClass = showTimePickerOnly
      ? styles.onlyTimeWrapper
      : showDatePickerOnly
      ? styles.onlyDateWrapper
      : styles.wrapper;

    return (
      <div className={styles.renderCustomView}>
        <div
          className={classnames(wrapperClass, {
            [styles.editProfileDateWrapper]: isEditProfileSection,
          })}
        >
          {!showTimePickerOnly && (
            <>
              <div className={styles.calendar}>{renderDefault()}</div>
              {showDivider && <div className={styles.divider} />}
            </>
          )}
          {showTimePickerUI && (
            <div
              className={styles.timePickerContainer}
              onClick={e => {
                saveInputValues();
                e.stopPropagation();
              }}
            >
              <div className={styles.timePicker}>
                {showHourInput ? (
                  <div className={styles.inputField}>
                    <input
                      ref={hourInput}
                      type={'number'}
                      defaultValue={HOUR_OPTIONS[Math.abs(hourIndex)]}
                      className={classnames(styles.selected, {
                        [styles.editProfileSelected]: isEditProfileSection,
                      })}
                      onClick={() => setHourInputDisplay(true)}
                      onWheel={e => {
                        // Preventing scroll for number input
                        e.target.blur();
                      }}
                      onChange={() => updateHourInputValue()}
                      data-testid={`${dataTestId}-hour-input`}
                    />
                  </div>
                ) : (
                  <div
                    className={styles.scrollSelect}
                    onWheel={handleHourScroll}
                  >
                    <div className={styles.prev}>
                      <div className={styles.scrollOptionSmall}>
                        {HOURS.getItem(hourIndex, -2)}
                      </div>
                      <div className={styles.scrollOption}>
                        {HOURS.getItem(hourIndex, -1)}
                      </div>
                    </div>
                    <div
                      className={classnames(styles.selected, {
                        [styles.editProfileSelected]: isEditProfileSection,
                      })}
                      onClick={e => {
                        e.stopPropagation();
                        saveInputValues();
                        setHourInputDisplay(true);
                      }}
                      data-testid={`${dataTestId}-hour-div`}
                    >
                      {HOURS.getItem(hourIndex, 0)}
                    </div>
                    <div className={styles.next}>
                      <div className={styles.scrollOption}>
                        {HOURS.getItem(hourIndex, 1)}
                      </div>
                      <div className={styles.scrollOptionSmall}>
                        {HOURS.getItem(hourIndex, 2)}
                      </div>
                    </div>
                  </div>
                )}
                <div>:</div>
                {showMinuteInput ? (
                  <div className={styles.inputField}>
                    <input
                      ref={minuteInput}
                      type={'number'}
                      defaultValue={MINUTE_OPTIONS[Math.abs(minuteIndex)]}
                      className={classnames(styles.selected, {
                        [styles.editProfileSelected]: isEditProfileSection,
                      })}
                      onClick={() => setMinuteInputDisplay(true)}
                      onWheel={e => {
                        // Preventing scroll for number input
                        e.target.blur();
                      }}
                      onChange={() => updateMinuteInputValue()}
                      data-testid={`${dataTestId}-minute-input`}
                    />
                  </div>
                ) : (
                  <div
                    className={styles.scrollSelect}
                    onWheel={handleMinuteScroll}
                  >
                    <div className={styles.prev}>
                      <div className={styles.scrollOptionSmall}>
                        {MINUTES.getItem(minuteIndex, -2)}
                      </div>
                      <div className={styles.scrollOption}>
                        {MINUTES.getItem(minuteIndex, -1)}
                      </div>
                    </div>
                    <div
                      className={classnames(styles.selected, {
                        [styles.editProfileSelected]: isEditProfileSection,
                      })}
                      onClick={e => {
                        e.stopPropagation();
                        saveInputValues();
                        setMinuteInputDisplay(true);
                      }}
                      data-testid={`${dataTestId}-minute-div`}
                    >
                      {MINUTES.getItem(minuteIndex, 0)}
                    </div>
                    <div className={styles.next}>
                      <div className={styles.scrollOption}>
                        {MINUTES.getItem(minuteIndex, 1)}
                      </div>
                      <div className={styles.scrollOptionSmall}>
                        {MINUTES.getItem(minuteIndex, 2)}
                      </div>
                    </div>
                  </div>
                )}
                <div className={styles.scrollSelect} onWheel={toggleMeridian}>
                  <div
                    className={styles.prev}
                    style={
                      meridianValue === MERIDIAN_OPTIONS.AM
                        ? { display: 'none' }
                        : { top: '-25px' }
                    }
                  >
                    <div className={styles.scrollOption}>
                      {MERIDIAN_OPTIONS.AM}
                    </div>
                  </div>
                  <div
                    className={classnames(styles.selected, {
                      [styles.editProfileSelected]: isEditProfileSection,
                    })}
                    onClick={e => {
                      saveInputValues();
                      toggleMeridian(e);
                      e.stopPropagation();
                    }}
                    data-testid={`${dataTestId}-meridian-input`}
                  >
                    {meridianValue}
                  </div>
                  <div
                    className={styles.next}
                    style={
                      meridianValue === MERIDIAN_OPTIONS.PM
                        ? { display: 'none' }
                        : { bottom: '-25px' }
                    }
                  >
                    <div className={styles.scrollOption}>
                      {MERIDIAN_OPTIONS.PM}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={styles.footerActions}>
          <div
            className={classnames(styles.saveButton, {
              [styles.editProfileDateSaveBtn]: isEditProfileSection,
            })}
            onClick={onDone}
            data-testid={`${dataTestId}-done-button`}
          >
            Done
          </div>
        </div>
      </div>
    );
  };

  const renderDay = (props, currentDate, selectedDate) => {
    const isSelected = selectedDate
      ? selectedDate.isSame(currentDate, 'day')
      : false;

    let oldMonth,
      newMonth,
      disabled = false;
    if (props && props.className) {
      oldMonth = props.className.includes('rdtOld');
      newMonth = props.className.includes('rdtNew');
      disabled = props.className.includes('rdtDisabled');
    }
    const notThisMonth = oldMonth || newMonth;

    return (
      <td
        {...props}
        className={classnames(styles.calendarDate, {
          [styles.notThisMonth]: notThisMonth,
          [styles.dateDisabled]: disabled,
        })}
      >
        {isSelected ? (
          <div
            className={classnames(styles.selected, {
              [styles.editProfileSelected]: isEditProfileSection,
            })}
          >
            {currentDate.date()}
          </div>
        ) : (
          currentDate.date()
        )}
      </td>
    );
  };

  const labelText = label ? label : placeholder;
  const labelValue = isEditProfileSection
    ? labelText + (isMandatory ? '*' : '')
    : removeAsteriskFromLabel(labelText);

  return (
    <div
      className={classnames(styles.containerWrapper, {
        [styles.containsError]: !!error && touched,
      })}
    >
      <div
        className={classnames(styles.container, {
          [styles.sponsorLandingPageInputContainer]: isSponsorLandingPage,
          [styles.editProfileInputContainer]: isEditProfileSection,
        })}
      >
        <div
          className={classnames({
            [styles.titleLabelContainer]:
              !isSponsorLandingPage && !isEditProfileSection,
          })}
        >
          {(label && label.length) || (placeholder && placeholder.length) ? (
            <FieldLabel
              value={labelValue}
              className={classnames(labelStyles, {
                ['sponsorLandingPageLabel']: isSponsorLandingPage,
              })}
              isMandatory={isMandatory && !isEditProfileSection}
            />
          ) : null}
          {!!ianaCode && (
            <>
              <FieldLabel
                value={`(In ${ianaCode})`}
                className={labelStyles}
                isMandatory={isMandatory}
              />
            </>
          )}
          {label && label.length && tooltip ? (
            <TooltipDiv
              dataFor={`tooltip_datepicker_${label}`}
              tooltipText={tooltip}
            />
          ) : null}
        </div>
        <Modal
          noHeader={true}
          styleClass={IModalTypes.DATE_TIME_PICKER}
          disableClose={true}
          className={styles.dateTimePickerModal}
          noBodyPadding={showTimePickerOnly}
          trigger={({ setShow }) => (
            <input
              className={classnames(styles.dateTimeInputField, {
                [styles.sponsorLandingPageInputField]: isSponsorLandingPage,
                [styles.editProfileInputField]: isEditProfileSection,
              })}
              value={dateTimeDisplay || ''}
              disabled={readOnly}
              placeholder={
                isSponsorLandingPage
                  ? removeAsteriskFromLabel(placeholder)
                  : placeholder
              }
              onClick={() => {
                setShow(true);
              }}
            />
          )}
          render={({ setShow }) => {
            closeModal.current = () => setShow(false);
            return (
              <Datetime
                timeFormat={false}
                renderView={(mode, renderDefault) =>
                  renderCustomView(mode, renderDefault)
                }
                renderDay={renderDay}
                locale={'en'}
                initialValue={new Date(new Date().toISOString())}
                open={true}
                input={false}
                value={dateObject}
                closeOnSelect={false}
                updateOnView={'days'}
                isValidDate={current => {
                  return current.isAfter(yesterday) || showDatePickerOnly;
                }}
                data-testid="dateTimePicker"
                onChange={selection => {
                  if (!selection || !selection.isValid()) return;
                  // The selection returned is a moment object.
                  const selectedDate = selection.toDate();
                  if (isNaN(selectedDate) === false) {
                    setDateObject(selectedDate);
                  }
                }}
              />
            );
          }}
        />
        {error && touched && (
          <div className={styles.submitErrorText}>
            <FormValidationErrorTooltip
              value={error}
              dataTestId={`${dataTestId}-error`}
            />
          </div>
        )}
      </div>
    </div>
  );
};

// export default React.memo(ColorPickerField);
export default DateTimePickerField;
