import { IContentTextSizeTypes, IContentTextStyleTypes, IContentTextColorTypes } from '@/types';

export interface IHeadingProps {
  headingColor?: IContentTextColorTypes;
  headingSize?: IContentTextSizeTypes;
  headingStyle?: IContentTextStyleTypes;
  onClick?: any;
  underlineStyle?: IHeadingUnderlineTypes;
  text: string;
  className?: string;
  textAlign;
  block: boolean;
}

export enum IHeadingUnderlineTypes {
  DEFAULT = '',
  NONE = 'none',

  CENTER = 'centerUnderline',
  LEFT = 'leftUnderline',
  RIGHT = 'rightUnderline',
}

