import React from 'react';
import classnames from 'classnames';
// types
import { IHeadingProps, IHeadingUnderlineTypes } from './types';
import { IContentTextSizeTypes, IContentTextStyleTypes, IContentTextColorTypes } from '@/types';
// styles
import styles from './styles.module.scss';

const Heading = (props: IHeadingProps) => {
  const {
    className,
    headingColor = IContentTextColorTypes.DEFAULT,
    headingSize = IContentTextSizeTypes.DEFAULT,
    headingStyle = IContentTextStyleTypes.DEFAULT,
    textAlign,
    onClick,
    text,
    underlineStyle = IHeadingUnderlineTypes.DEFAULT,
    block = false,
  } = props;

  const headingClassName = classnames(
    styles[headingColor],
    styles[headingSize],
    styles[headingStyle],
  );

  const blockStyle = block ? {
    display: 'block'
  } : {};

  const HeadingElement = (() => {
    switch (headingSize) {
      case IContentTextSizeTypes.XSMALL: {
        return <h5 style={blockStyle} className={headingClassName}>{text}</h5>;
      }
      case IContentTextSizeTypes.SMALL: {
        return <h4 style={blockStyle} className={headingClassName}>{text}</h4>;
      }
      case IContentTextSizeTypes.MEDIUM: {
        return <h3 style={blockStyle} className={headingClassName}>{text}</h3>;
      }
      case IContentTextSizeTypes.LARGE: {
        return <h2 style={blockStyle} className={headingClassName}>{text}</h2>;
      }
      case IContentTextSizeTypes.XLARGE: {
        return <h1 style={blockStyle} className={headingClassName}>{text}</h1>;
      }
      default: {
        return <h6 style={blockStyle} className={headingClassName}>{text}</h6>;
      }
    }
  })();

  if (underlineStyle === IHeadingUnderlineTypes.NONE) {
    return HeadingElement;
  }

  return (
    <div style={{ textAlign }} className={classnames(styles.headingWithUnderline, className)} onClick={onClick}>
      {HeadingElement}
      <div className={styles[underlineStyle]} >
        <div className={styles.headingUnderline} />
      </div>
    </div>
  )
};

export {
  IContentTextSizeTypes as IHeadingSizes,
  IContentTextStyleTypes as IHeadingStyles,
  IContentTextColorTypes as IHeadingColors,
  IHeadingUnderlineTypes as IUnderlineTypes,
};
export default Heading;
