import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
// components
import ReactTooltip from 'react-tooltip';
// config
import { getIcon, getTooltipText } from './config';
// styles + types
import { STANDARD_ICON_BUTTON_SIZE } from '@/styles/iconSizes';
import styles from './styles.module.scss';
import { IIconButtonProps, IIconButtonTypes, TooltipTypes } from './types';
import { IContentColors, IUIIcons, IContentBGColorTypes } from '@/types';
import { useMediaQuery } from 'react-responsive';

const IconButton = (props: IIconButtonProps) => {
  const {
    activeIcon,
    className,
    defaultIcon,
    disabled,
    iconBG = '',
    iconSize = STANDARD_ICON_BUTTON_SIZE,
    onClick,
    showDefault,
    tooltip = TooltipTypes.top,
    type = IIconButtonTypes.ROUND_NEUTRAL_BASE_1,
    style,
    iconStyle,
    id,
    overlayIcon,
    overlayIconClassName,
    triggerTooltip = false,
    hideAfterSeconds,
    tooltipText,
    dataTestId,
  } = props;

  const buttonRef = useRef(null);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const isHoverSupported = useMediaQuery({ query: '(hover: hover)' });

  const iconColor = (() => {
    if (
      [
        IIconButtonTypes.SQUARE_NEUTRAL_BASE_3,
        IIconButtonTypes.SQUARE_BLACK_CONTRAST,
      ].includes(type)
    ) {
      return IContentColors.WHITE;
    }
    if ([IIconButtonTypes.ROUND_DISABLED].includes(type)) {
      return IContentColors.SILVER_CHALICE;
    }

    if ([IIconButtonTypes.ROUND_NEUTRAL_BASE_1].includes(type)) {
      return IContentColors.NEUTRAL_CONTRAST_1;
    }

    if (
      [
        IIconButtonTypes.ROUND_NEUTRAL_BASE_3,
        IIconButtonTypes.ROUND_CONTRAST,
      ].includes(type)
    ) {
      return IContentColors.MONOCHROME_CONTRAST;
    }

    if (
      [
        IIconButtonTypes.ROUND_THEME,
        IIconButtonTypes.ONLY_ICON_CONTRAST_4,
      ].includes(type)
    ) {
      return IContentColors.NEUTRAL_CONTRAST_4;
    }

    if ([IIconButtonTypes.ONLY_ICON_GREY_BLACK].includes(type)) {
      if (showDefault) {
        return IContentColors.NEUTRAL_MID_2;
      }
      return IContentColors.PRIMARY;
    }
    if ([IIconButtonTypes.ROUND_GREY_BLACK].includes(type)) {
      return IContentColors.WHITE;
    }
    if ([IIconButtonTypes.ROUND_DARK_GREY_WHITE].includes(type)) {
      if (showDefault) {
        return IContentColors.NEUTRAL_CONTRAST_2;
      }
      return IContentColors.NEUTRAL_CONTRAST_3;
    }
    if ([IIconButtonTypes.ROUND_WHITE_DARK_GREY].includes(type)) {
      if (showDefault) {
        return IContentColors.WHITE;
      }
      return IContentColors.NEUTRAL_MID_1;
    }
    if (IIconButtonTypes.ROUND_PRIMARY === type) {
      return IContentColors.SECONDARY;
    }
    return IContentColors.WHITE;
  })();
  const iconType = showDefault ? defaultIcon : activeIcon;
  const icon = getIcon(iconType, iconSize, iconColor, styles, iconStyle);
  const overlayIconJSX = getIcon(
    overlayIcon,
    iconSize,
    iconColor,
    styles,
    iconStyle,
  );

  const tooltipProps = (() => {
    if (tooltip === TooltipTypes.none || !isHoverSupported) {
      return {};
    }

    if (
      defaultIcon === IUIIcons.HEART_FILLED ||
      defaultIcon === IUIIcons.FILTER_FILLED
    ) {
      return {
        'data-place': tooltip,
        'data-arrow-color': '#808080',
        'data-tip': tooltipText || getTooltipText(iconType),
      };
    }

    if (defaultIcon === IUIIcons.EDIT_FILLED) {
      return {
        'data-place': tooltip,
        'data-multiline': true,
        'data-arrow-color': '#808080',
        'data-tip': tooltipText || getTooltipText(iconType),
      };
    }

    if (defaultIcon === IUIIcons.SEND) {
      if (triggerTooltip) {
        return {
          'data-place': tooltip,
          'data-multiline': true,
          'data-arrow-color': '#F0B429',
          'data-background-color': '#F0B429',
          'data-tip': tooltipText || getTooltipText(iconType),
        };
      }

      if (!triggerTooltip) {
        return {
          'data-tip-disable': true,
        };
      }
    }

    if (
      defaultIcon === IUIIcons.EXTERNAL_LINK_LINE ||
      defaultIcon === IUIIcons.BOOTH_VOLUME_ON
    ) {
      return {
        'data-place': TooltipTypes.left,
        'data-multiline': true,
        'data-arrow-color': '#F0B429',
        'data-background-color': '#F0B429',
        'data-tip': getTooltipText(iconType),
      };
    }

    return {
      'data-place': tooltip,
      'data-tip': tooltipText || getTooltipText(iconType),
    };
  })();

  const toolTipClassName = (() => {
    if (
      defaultIcon === IUIIcons.NEW_VOLUME_ON ||
      activeIcon === IUIIcons.NEW_VOLUME_OFF
    ) {
      return styles.volumeTooltip;
    }
    // Profile Modal
    if (
      defaultIcon === IUIIcons.HEART_FILLED ||
      defaultIcon === IUIIcons.FILTER_FILLED
    ) {
      return styles.profileModalTooltip;
    }
    if (defaultIcon === IUIIcons.EDIT_FILLED) {
      return styles.profileModalTooltipMultiline;
    }
    // Send Icon
    if (defaultIcon === IUIIcons.SEND) {
      return styles.chatIconTooltip;
    }
    return styles.tooltip;
  })();

  useEffect(() => {
    if (!hideAfterSeconds) {
      return;
    }

    if (triggerTooltip) {
      const timeOutId = setTimeout(() => {
        ReactTooltip.hide(buttonRef?.current);
      }, hideAfterSeconds * 1000);
      ReactTooltip.show(buttonRef?.current);

      // eslint-disable-next-line consistent-return
      return () => clearTimeout(timeOutId);
    }
  }, [triggerTooltip]);

  return (
    <button
      aria-label={tooltipText || getTooltipText(iconType)}
      id={id}
      ref={buttonRef}
      className={classnames(
        styles.baseIconButton,
        className,
        { [styles[`${type}Default`]]: showDefault },
        { [styles[`${type}Active`]]: !showDefault },
        { [styles.disabled]: disabled },
        styles[iconBG],
      )}
      disabled={disabled}
      onClick={onClick}
      data-for={`icon-button-tooltip-${iconType}`}
      {...tooltipProps}
      type="button"
      style={style}
      data-testid={dataTestId}
      title={tooltipText || getTooltipText(iconType)}
    >
      {overlayIcon && (
        <div className={classnames(styles[overlayIconClassName])}>
          {overlayIconJSX}
        </div>
      )}
      {(!isMobile || !isHoverSupported) && (
        <ReactTooltip
          id={`icon-button-tooltip-${iconType}`}
          effect="solid"
          className={classnames(toolTipClassName)}
          uuid={uuidv4()}
          globalEventOff={isMobile ? 'click' : undefined}
          arrowColor="transparent"
        />
      )}
      {icon}
    </button>
  );
};

export {
  IContentBGColorTypes as IIconButtonBGColors,
  IIconButtonTypes,
  IUIIcons,
};
export default IconButton;
